import './CompFiveFAQ.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";


function CompFiveFAQ() { 


  const [ cardActiv, setCardActiv] = useState('');

  return (

          <div className="CompFiveFAQ text-center pt-80 max-sm:pb-80 mt-10 max-sm:mb-80 sm:mt-80">
  	      <div className="text-center justify-center items-center pt-10 max-sm:pb-80">
			<h1 className=''>FAQ</h1>
			<div className='col-sm-12'>
				  <div className='col-sm-12 col-md-12 col-lg-3'></div>
				  <div className='col-sm-12 col-md-12 col-lg-6'>
                  	<div className={` mb-3  ${cardActiv === '1'?'profileDataCard white-glassmorphism-faq':'mt-10 mb-10'}`}> 
					  <div className={`flex justify-between items-center py-3 landingCompFourColor text-white ${cardActiv === '1' ? 'rounded-t-lg' : 'rounded-full'}`}>
							<h3 className="mt-5 mb-5 pb-1 mx-10 max-sm:text-3xl text-4xl text-left">Ist Bluumer kostenlos?</h3>
							{cardActiv === '1'? <div className='mx-10' onClick={() => setCardActiv('99')}><FaMinus /></div>
											  :<div className='mx-10' onClick={() => setCardActiv('1')}><FaPlus /></div>}
						</div>
						{ cardActiv === '1' && (
							<div>
								<div className='mx-6'><hr className='horizontalLine' /></div>
								<p className='p-3 text-3xl'>Die komplette Plattform befindet sich in der Alpha-Version und ist derzeit vollkommen kostenlos. 
									Alle Benutzer werden rechtzeitig mehrfach mit Informationen und Angeboten benachrichtigt, bevor die Plattform plant, kostenpflichtig zu werden.</p>
							</div>
							)}
		  			</div>
					<div className='col-sm-12 col-md-12 col-lg-3'></div>
					</div>
		  		</div>  
				  <div className='col-sm-12'>
				  <div className='col-sm-12 col-md-12 col-lg-3'></div>
				  <div className='col-sm-12 col-md-12 col-lg-6'>
                  	<div className={`mb-3 ${cardActiv === '2'?'profileDataCard white-glassmorphism-faq':'mt-10 mb-10'}`}> 
					  <div className={`flex justify-between items-center py-3 landingCompFourColor text-white ${cardActiv === '2' ? 'rounded-t-lg' : 'rounded-full'}`}>
							<h4 className="mt-5 mb-5 pb-1 mx-10 max-sm:text-3xl text-4xl text-left">Wann wird Bluumer kostenpflichtig?</h4>
							{cardActiv === '2'? <div className='mx-10' onClick={() => setCardActiv('99')}><FaMinus /></div>
											  :<div className='mx-10' onClick={() => setCardActiv('2')}><FaPlus /></div>}
						</div>
					{ cardActiv === '2' && (
						<div>
						    <div className='mx-6'><hr className='horizontalLine' /></div>
						<p className='p-3 text-3xl'>Die Plattform befindet sich in der Alpha-Version, und die Weiterentwicklung wird kontinuierlich fortgesetzt. Welche Monetarisierungsstrategie angewandt wird und wann, steht noch nicht fest. 
							Allerdings wird Bluumer erst kostenpflichtig, sobald die Nutzerzahl eine gewisse Schwelle erreicht hat und der Kundennutzen sowie der erhaltene Wert dies auch rechtfertigen.</p>
					</div>
					)}
		  			</div>
					<div className='col-sm-12 col-md-12 col-lg-3'></div>
					</div>
		  		</div>  
				  <div className='col-sm-12'>
				  <div className='col-sm-12 col-md-12 col-lg-3'></div>
				  <div className='col-sm-12 col-md-12 col-lg-6'>
                  	<div className={` mb-3 ${cardActiv === '3'?'profileDataCard white-glassmorphism-faq':'mt-10 mb-10'}`}> 
					  <div className={`flex justify-between items-center py-3 landingCompFourColor text-white ${cardActiv === '3' ? 'rounded-t-lg' : 'rounded-full'}`}>
							<h4 className="mt-5 mb-5 pb-1 mx-10 max-sm:text-3xl text-4xl text-left">Welche Vorteile bietet die Warteliste?</h4>
							{cardActiv === '3'? <div className='mx-10' onClick={() => setCardActiv('99')}><FaMinus /></div>
											  :<div className='mx-10' onClick={() => setCardActiv('3')}><FaPlus /></div>}
						</div>
					{ cardActiv === '3' && (
						<div>
						    <div className='mx-6'><hr className='horizontalLine' /></div>
						<p className='p-3 text-3xl'>Mit dem Eintrag auf die Warteliste wirst du über alle Neuigkeiten informiert, bist sofort startklar beim Launch, erhältst in 
							Zukunft Vorteile und unterstützt die Plattform durch deine Antworten auf ein paar Fragen, um sie für dich zu verbessern und noch nützlicher zu machen.</p>
					</div>
					)}
		  			</div>
					<div className='col-sm-12 col-md-12 col-lg-3'></div>
					</div>
		  		</div> 
				  <div className='col-sm-12'>
				  <div className='col-sm-12 col-md-12 col-lg-3'></div>
				  <div className='col-sm-12 col-md-12 col-lg-6'>
                  	<div className={`mb-3 ${cardActiv === '4'?'profileDataCard white-glassmorphism-faq':'mt-10 mb-10'}`}> 
					  <div className={`flex justify-between items-center py-3 landingCompFourColor text-white ${cardActiv === '4' ? 'rounded-t-lg' : 'rounded-full'}`}>
							<h4 className="mt-5 mb-5 pb-1 mx-10 max-sm:text-3xl text-4xl text-left">Wie setze ich mich auf die Warteliste?</h4>
							{cardActiv === '4'? <div className='mx-10' onClick={() => setCardActiv('99')}><FaMinus /></div>
											  :<div className='mx-10' onClick={() => setCardActiv('4')}><FaPlus /></div>}
						</div>
					{ cardActiv === '4' && (
						<div>
						    <div className='mx-6'><hr className='horizontalLine' /></div>
						<p className='p-3 text-3xl'>Trage einfach deine E-Mail-Adresse ein, drücke „Warteliste anmelden“ und beantworte ein paar Fragen zum Thema Buch-Bereich.
						Klicke anschließend „Warteliste beitreten“ und schon bleibst du informiert.			
						</p>
					</div>
					)}
		  			</div>
					<div className='col-sm-12 col-md-12 col-lg-3'></div>
					</div>
		  		</div> 
				  <div className='col-sm-12'>
				  <div className='col-sm-12 col-md-12 col-lg-3'></div>
				  <div className='col-sm-12 col-md-12 col-lg-6 '>
                  	<div className={` mb-3  ${cardActiv === '5'?'profileDataCard white-glassmorphism-faq ':'mt-10 mb-10'}`}> 
					  <div className={`flex justify-between items-center py-3 landingCompFourColor text-white ${cardActiv === '5' ? 'rounded-t-lg' : 'rounded-full'}`}>
							<h4 className="mt-5 mb-5 pb-1 mx-10 max-sm:text-3xl text-4xl text-left">Kann ich mehrere Profile erstellen?</h4>
							{cardActiv === '5'? <div className='mx-10' onClick={() => setCardActiv('99')}><FaMinus /></div>
											  :<div className='mx-10' onClick={() => setCardActiv('5')}><FaPlus /></div>}
						</div>
						{ cardActiv === '5' && (
						<div>
						    <div className='mx-6'><hr className='horizontalLine' /></div>
							<p className='p-3 text-3xl'>Du hast die Möglichkeit, ein Profil als Autor, Lektor & Korrektor, Testleser, Verlag oder Coverdesigner zu erstellen.
							Sobald du mehrere Rollen nutzen möchtest, erstelle einfach die zusätzlichen Profile in deinem Benutzer. 
							</p>
						</div>
						)}
						</div>
						<div className='col-sm-12 col-md-12 col-lg-3 '></div>
					</div>
		  		</div>  
	    </div>
	</div>

  
  );
}

export default CompFiveFAQ; 
export { default as CompFiveFAQ } from './CompFiveFAQ';