import './CompTwoBenefits.css';

import { useEffect, useState } from 'react';

import { FaPlus } from "react-icons/fa";

import { FaPencilAlt } from "react-icons/fa";
import { FaGlasses } from "react-icons/fa6";
import { AiFillPicture } from "react-icons/ai";
import { FaBook } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";


function CompTwoBenefits() {

  
  return (
    <div className="CompTwoBenefits">
      <div className="py-5 text-center mx-5 lg:mx-40">
        <div className="text-center items-center justify-content-center h-100">
          <div className="mt-20 mb-20">
            <h1>Bluumer wurde geschaffen für ...</h1>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950">Autoren</h2>
              <div className="my-10">
                <FaBook className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du suchst Lektoren, Testleser oder Coverdesigner.</h3>
                </li>
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du willst deine Sichtbarkeit gegenüber Verlagen erhöhen.</h3>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950">Lektor & Coverdesigner</h2>
              <div className="my-10">
                <FaPencilAlt className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du willst deine Reichweite bei Kunden steigern.</h3>
                </li>
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du willst mehr Anfragen als je zuvor!</h3>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950">Testleser</h2>
              <div className="my-10">
                <FaGlasses className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du suchst Zugang zu hunderten nicht veröffentlichten Büchern.</h3>
                </li>
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du willst Geld sparen und gleichzeitig verborgene Juwelen entdecken.</h3>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center justify-center mt-10 mx-10">
              <h2 className="text-blue-950">Verlag</h2>
              <div className="my-10">
                <TbWorld className="transform scale-150" />
              </div>
              <ul className="text-justify">
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du willst Zeit und Geld sparen in der Akquise.</h3>
                </li>
                <li>
                  <h3 className="mt-2 mb-2 text-3xl">Du suchst qualitative Manuskripte & Autoren.</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

            
 
    
  );
}

export default CompTwoBenefits;
export { default as CompTwoBenefits } from './CompTwoBenefits';