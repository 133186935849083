import './ProfileAddEditor.css';
import api from '../../api/axiosConfig';
import { useEffect, useState, useCallback } from 'react';
import { Link } from "react-router-dom";

import logo from '../../images/shiny_symbol_withcircle1.png';

//Security
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { sanitizeInputTextArea } from '../../tools/security/SanitizeInputTextArea';
import { sanitizeInputTextAreaBig } from '../../tools/security/SanitizeInputTextAreaBig';
import { exeedsMaxCharacter } from '../../tools/security/exeedsMaxCharacter';
import { exeedsMaxCharacterTextArea } from '../../tools/security/exeedsMaxCharacterTextArea';
import { exeedsMaxCharacterTextAreaBig } from '../../tools/security/exeedsMaxCharacterTextAreaBig';

import ReactQuill from "react-quill";   
import 'react-quill/dist/quill.snow.css';


const ProfileAddEditor = ({baseUrl}) => {


    const [prevFileName, setPrevFileName,  ] = useState('');
    const [getFileName, setFileName,  ] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');

    const [getBelongName, setBelongName,  ] = useState([]);
    const [render, setRender] = useState(false);    
    const [imageSrc, setImageSrc] = useState(null);

    const [getUserName, setUserName] = useState();
    const [getIsConfirmed, setIsConfirmed] = useState();
    const [getToken, setToken] = useState();

  const [entitySocial, setEntitySocial] = useState('');
  const [entityExp, setEntityExp] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityAge, setEntityAge] = useState('');
  const [entityWebsite, setEntityWebsite] = useState('');
    
    const [getFileSizeToBig, setFileSizeToBig] = useState(false);
    const [getFileTypeWrong, setFileTypeWrong] = useState(false);
    
    const [getCharacterCountSoc, setCharacterCountSoc] = useState(0);
    const [getCharacterCountExp, setCharacterCountExp] = useState(0);

    const [showGenreChange, setGenreChange] = useState(false);

    const [error, setError] = useState(null);

    const modules = {
      toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],  
        [{ 'align': [] }],
        ['link'],
        ['clean']
    ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
    }
  }

  
const handleContentQuillExpChange = useCallback((value) => {
  try {
    const characterCount = value.replace(/<[^>]*>/g, '').length; // Strip HTML tags and count characters
    setCharacterCountExp(characterCount);
    if(characterCount <= 3000){    
      setEntityExp(value); 
    }
  } catch (error) {
    handleError('Error handling quill exp change:', error);
  }
}, []);

    const handleSocialChange = (event) => {
      try {
        const input = exeedsMaxCharacterTextArea(event.target.value);
        setEntitySocial(input);
        setCharacterCountSoc(input.length);
      } catch (error) {
        handleError('Error handling social change:', error);
      }
    };
    
    
    const handleNameChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacter(e.target.value);
        setEntityName(inputValue);
        setBelongName(inputValue);
      } catch (error) {
        handleError('Error handling name change:', error);
      }
    };
    
    const handleAgeChange = (e) => {
      try {
        const value = e.target.value
        const regex = /^\d*$/; 

        if (regex.test(value) && (value === '' || parseInt(value) <= 125)) {
          const inputValue = exeedsMaxCharacter(value);
          setEntityAge(inputValue);
        }

      } catch (error) {
        handleError('Error handling age change:', error);
      }
    };
    
    
    const handleWebsiteChange = (e) => {
      try {
        const inputValue = exeedsMaxCharacter(e.target.value);
        setEntityWebsite(inputValue);
      } catch (error) {
        handleError('Error handling website change:', error);
      }
    };
    
   const entityHandleSubmit = async (e) => {
      try {
        e.preventDefault();
    
        const socialMediaAccounts = entitySocial.split('\n').map(account => account.trim());
    
        const entityNameVar = SanitizeInput(entityName);
        const ageVar = SanitizeInput(entityAge);
        const experienceVar = sanitizeInputTextAreaBig(entityExp);
        const websiteVar = SanitizeInput(entityWebsite);
        const socialMediaAccountsVar = socialMediaAccounts
          .map(account => sanitizeInputTextArea(account))
          .filter(account => account.trim() !== "");
    
        const fileNameVar = SanitizeInput(getFileName);
    
        const addNewEntityData = {  
          entityName: entityNameVar,
          age: ageVar,
          experience: experienceVar,
          website: websiteVar,
          socialMediaAccounts: socialMediaAccountsVar,
          ...(fileNameVar !== '' && { profileImg: fileNameVar }),
        };
    
        const response = await api.post("/api/editors", addNewEntityData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
      });

	if(response != null){
        	const newEntityData2 = {
          		userName: localStorage.getItem('userEmail'),
          		userProfileName: getBelongName,
          		userProfileType: "Editor",
        	};
       
        	const response2 = await api.post(`/api/userProfile`, newEntityData2, {
          		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        	});

		if(response2 != null){
			setIsConfirmed(true);
		}
	}

  if (response && response.data) {

    const addNewGenreData = {
      ...checkedGenreOptions // Include all genre options
    };

    const profileType = "Editor";

      const response5 = await api.put(`/api/genres/update/${entityNameVar}&${profileType}`, addNewGenreData);
      if(response5.data != null){

      }else {
      handleError("Updating / Creating Genres ...");
    }
 }


      } catch (error) {
        handleError('Error submitting Entity data:', error);
      }
    };


    const rerender = () => {
      setRender(true);
    };
    
 
    const handleFileChange = (e) => {
      try {
        setPrevFileName(getFileName);
        const filename = e.target.files[0].name;
        setFileName(filename);     
        setSelectedFile(e.target.files[0]);
  
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
  
        setRender(true);
      } catch (error) {
        handleError('Error handling file change:', error);
      }
    };

    
    const handleFileUpload = async (e) => {
      try {
        e.preventDefault();
        const fileInput = e.target.querySelector('input[type="file"]');
        const file = fileInput.files[0];
    
        if (!file) {
          handleError('No file selected');
          return;
        }
    
        const allowedFileTypes = ['image/jpeg', 'image/png'];
        if (!allowedFileTypes.includes(file.type)) {
          setFileTypeWrong(true);
          handleError('Invalid file type');
          return;
        }
    
        const maxSize = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxSize) {
          setFileSizeToBig(true);
          handleError('File size exceeds the limit of 5 MB');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uploadName', getBelongName);
        formData.append('belongName', getBelongName);
    
        const response = await api.post('/api/files/uploadProfileImg', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
      setTimeout(() => {
        // Update the state to trigger a re-render
        fetchImage();
      }, 1000); // Delay in milliseconds (adjust as needed)
  
      if(prevFileName !== null && prevFileName !== ''){
          const response2 = await api.delete(`/api/files/delete/${prevFileName}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
       }

      } catch (error) {
        handleError('Error uploading file:', error);
      }
    };
    
    const fetchImage = async () => {
      try {
        const newImageSrc = await api.get(`/api/files/image/${getFileName}`);
        setImageSrc(`${baseUrl}/api/files/image/${getFileName}`);
      } catch (error) {
        handleError('Error fetching image:', error);
      }
    };

    const [checkedGenreOptions, setCheckedGenreOptions] = useState({
      fiction: false, nonFiction: false, mystery: false, thriller: false, romance: false,
      fantasy: false, scienceFiction: false, horror: false, historical: false, youngAdult: false, newAdult: false,
       children: false, adventure: false, biography: false, selfHelp: false, poetry: false, drama: false,
      crime: false, graphicNovels: false, shortStories: false, dystopian: false, paranormal: false, memoir: false,
       cookbooks: false, spirituality: false, travel: false, science: false, history: false
    });
    
    const handleGenreCheckboxChange = (option) => {
      setCheckedGenreOptions((prevOptions) => ({
        ...prevOptions,
        [option]: !prevOptions[option],
      }));
    };
    
   
      useEffect(() => {
          setFileName(" ");
          setIsConfirmed(false);
      }, []);

                  
     useEffect(() => {
            if (render) {
            // Fetch and update the image source after a successful upload
            fetchImage();
            setRender(false);
            setFileTypeWrong(false);  
            setFileSizeToBig(false);       
            }
    }, [render]);
            

    const handleError = (message, error) => {
      console.error(message, error);
      setError('An error occurred. Please try again.');
      };


  return (
    <div className="ProfileAddEditor ">
      <div className="row w-full">
        <div className="row row-col-12 text-center m-4">
                {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
                {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei  berschreitet 5 MB an Größe!</p></div>)}
                {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ PNG oder JPEG sein!</p></div>)}
          </div>

      <div className="container ">
          
        <div className="row row-col-4 ">
  
          {getIsConfirmed && (
            <div className="container py-5 h-100 text-center">
              <div className="row row-col-3 text-center h-100">
                <div className="col-sm-4 text-center"></div>
                <div className="col-sm-4 text-center colConfirmEmail">
                <div className="col-sm-12 items-center justify-content-center">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8"><img className="" src={logo} alt="logo" /></div>
                            <div className="col-sm-2"></div>
                      </div>
                  <h3 className="mt-1 mb-5 pb-1">Du hast erfolgreich ein Lektoren & Korrektoren -Profil erzeugt!</h3>
                  <p>Bitte wechsle dein Profil im Menü oder schließe diese Seite. </p>
                  <div className="text-center pt-1 mb-5 pb-1">
                    <Link to="/myProfile">
                      <button className="buttonTextColor text-2xl py-3 px-20 mx-4 btnChange mb-3" type="button" >Zur Mein Profil - Seite</button>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-4 text-center"></div>
              </div>
            </div>
          )}
          </div> 

          <div className="row  ">
  
          {!getIsConfirmed && (
          <><div className=''>

                <form onSubmit={entityHandleSubmit} method="POST">
                  
                  <div className="row profileDataCard paddingOne">
                    <div className="row max-md:flex titleRowBackgroundcolor white-glassmorphism-card ">
                      <div className="col col-sm-4"><h2 className="FormStyling text-left titleTextColor">Stammdaten</h2></div>
                      <div className="col col-sm-8 FormStyling text-right justify-end w-full">
                        <button className="px-48 max-md:px-2 max-md:ml-8 py-2 bg-gray-600 text-white rounded mr-2">Profil erstellen</button>
                      </div> 
                     </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3">
                      <div className="col col-sm-2 max-sm:col-sm-3 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                        <div>
                          <h3 className="FormStyling">Name</h3>
                          <input className="FormStyling FormStyling2 w-full" name="entityName" onChange={handleNameChange} value={entityName} />
                          <br></br>
                          <h3 className="FormStyling">Alter</h3>
                          <input className="FormStyling FormStyling2 w-full" name="entityAge" onChange={handleAgeChange} value={entityAge} />
                        </div>
                      </div>
                      <div className="col col-sm-3 text-left" style={{ minWidth: '100px', minHeight: '180px' }}>
                        <div>
                          <h3 className="FormStyling">Website</h3>
                          <input className="FormStyling FormStyling2 w-full" name="entityWebsite" onChange={handleWebsiteChange} value={entityWebsite} />
                          <br></br>
                          <h3 className="FormStyling">Genres</h3>
                          <div className='buttonTwo btnChange text-center mx-4 mt-5 cursor-pointer' onClick={()=> setGenreChange(!showGenreChange)}>
                            Genres Bearbeiten
                          </div>
                        </div>
                      </div>
                      <div className="col col-sm-7 max-sm:col-sm-12 text-left">
                        <div className="row textAreaOne">
                          <h3 className="FormStyling">Social Media Accounts</h3>
                          <div className="row textAreaOne">
                            <textarea
                              style={{ overflowY: 'auto', maxHeight: '300px', maxWidth: '800px', minHeight: '180px', minWidth: '280px' }}
                              className="FormStyling FormStyling2 w-full flex-grow-1"
                              placeholder={'Füge jeden Acoount in eine Eigene Zeile!'}
                              name="entitySocial"
                              value={entitySocial}
                              onChange={handleSocialChange} />
                            <div
                              style={{
                                position: 'relative',
                                bottom: '25px', // Adjust the distance from the bottom as needed
                                right: '-10px', // Adjust the distance from the right as needed
                                color: 'gray',
                              }}
                            >
                              {getCharacterCountSoc} / 500
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    <div className="row mainRowBackgroundcolor white-glassmorphism-card pt-3" >
                      { showGenreChange && (
                        <div className="flex flex-wrap gap-4">
                            {Object.entries(checkedGenreOptions).map(([genre, isChecked]) => (
                              <div key={genre} className="flex items-center mb-4 mx-3 text-3xl text-[#8b90a5] hover:text-[#babbcf]">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handleGenreCheckboxChange(genre)}
                                  id={genre}
                                  className="cursor-pointer rounded items-center  mb-6 border-gray-300"
                                />
                                <label htmlFor={genre} className="capitalize mt-3 ml-2">{genre}</label>
                              </div>
                            ))}
                          </div>
                          )}
                      </div>
                  </div>
                    
                     <div className="row profileDataCard paddingOne">
                        <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                          <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Erfahrung</h2></div>
                        </div>
                        <hr className='horizontalLine' />
                     <div className="row mainRowBackgroundcolor">
                          <div className="col col-sm-12 text-left mainRowBackgroundcolor white-glassmorphism-card ">
                          <div className="row textAreaOne pt-5" style={{ width: '100%' }}>

                                <ReactQuill
                                    theme='snow'
                                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                                    placeholder="Deine Erfahrung..."
                                    modules={modules}
                                    onChange={handleContentQuillExpChange}
                                    value={entityExp}
                                />
                                    <div className='pt-6 pb-3'
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        right: '-10px', // Adjust the distance from the right as needed
                                        color: 'white',
                                      }}
                                      >
                                      {getCharacterCountExp} / 3000
                                    </div>  

                                {/*}	<div><DisplayHtml htmlContent={contentExpClean} /></div> */}

                                </div>
                          </div>
                        </div>
                      </div>   
                </form>
              </div>

              <div className="row profileDataCard paddingOne">
                        <div className="row titleRowBackgroundcolor  white-glassmorphism-card ">
                          <div className='col col-sm-4'><h2 className="FormStyling text-left titleTextColor">Profilbild</h2></div>
                        </div>
                        <hr className='horizontalLine' />
                     <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                          <div className="col col-sm-12 mainRowBackgroundcolor white-glassmorphism-card ">
                            
              
                          <div className="col col-sm-4 flex text-center justify-center items-center">
                            <div className="">
                              <div>
                                <div className="text-left ml-2.5 my-10 max-sm:my-0" style={{ minWidth: '100px', minHeight: '240px' }}>
                                <div><h3 className='text-center'>Vorschau</h3></div>
                                  <div className="" style={{ minWidth: '100px', minHeight: '240px' }}>
                                    <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }}>
                                      <div>
                                      {imagePreview && (
                                        <img src={imagePreview} alt="Preview" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }} />
                                      )}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      <div className="col col-sm-4 text-center justify-center mt-48 max-md:mt-12">
                              <div className="textAreaOne ">
                                <form className='' onSubmit={handleFileUpload} method="POST" encType="multipart/form-data">
                                  <input className='w-full text-center justify-center items-center' type="file" onChange={handleFileChange} />
                                  <br></br>
                                  <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                                </form>
                              </div>
                      </div>
                      <div className="col col-sm-4 flex text-center justify-center items-center">
                            <div className="">
                              <div>
                                <div className="my-10" style={{ minWidth: '100px', minHeight: '240px' }}>
                                   <div><h3 className='text-center'>Hochgeladen</h3></div>
                                  <div className=" " style={{ minWidth: '100px', minHeight: '240px' }}>
                                    <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }}>
                                      <div>
                                      {imageSrc && (
                                        <img className="" src={imageSrc} alt="Uploaded" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }} />
                                        )}
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                </div>
              </div>
            </div>
             
                  
            </>      
            
        )}
      </div>
      
    </div>   
  </div> 
</div>           
    
  );
}

export default ProfileAddEditor; 
export { default as ProfileAddEditor } from './ProfileAddEditor';