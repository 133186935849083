import './CompThreePic.css';

import Pic1 from '../../images/glühbirne.jpg';
import Pic2 from '../../images/landingAuthor2.jpg';
import Pic3 from '../../images/landingPublisher2.jpg';
import Pic4 from '../../images/landingCover2.jpg';

function CompThreePic() { 


  return (

<div className="CompThreePic text-center max-sm:mb:10 max-sm:pb-10 mt-80 pt-80 max-sm:pt-80">
	
      <div className="py-20">
        <div className=" pl-5 pr-12 px-4">
          <div className="flex flex-col lg:flex-row w-full items-center justify-center max-sm:mb:90">
          <div className="col-sm-1 px-40"></div>
            <div className="col-sm-10  text-center px-40">
              <h2 className="text-4xl lg:text-5xl font-bold mb-4 text-center">Dein Tor zu qualitativen Dienstleistungen, 
              Expertise und unentdeckten Bestsellern. </h2>
            </div>
          </div>
        </div>
      </div>

        <div className="compThreeBGColor py-10 mb-10 max-xl:mt-10 xl:mt-20" style={{  maxHeight: 200, minHeight: 100 }}>
          <div className=" pl-5 pr-12 px-4">
            <div className="flex flex-col lg:flex-row w-full items-center justify-center">
              <div className="col-sm-4 lg:mb-0 relative max-lg:hidden">
                <img src={Pic2} alt="Landing Page" className="landingCard w-full h-full  object-cover scale-75 z-10" style={{  maxHeight: 180, marginTop: '-20%', marginBottom: '-20%' }} />
              </div>
            <div className="col-sm-1  text-white  px-40"></div>
              <div className="col-sm-8  text-white  px-40">
                <h4 className="text-center">
                    Verwandle als Autor dein rohes Manuskript zu einem hochwertigen Buch.
                    An einem Ort, vom Traum zum Bestseller.
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="compThreeBGColorReverse py-10 mb-10 max-xl:mt-10 xl:mt-20" style={{   maxHeight: 260, minHeight: 60 }}>
          <div className=" pl-5 pr-12 px-4">
            <div className="flex flex-col lg:flex-row w-full items-center justify-center">
            <div className="col-sm-8  text-white  px-40">
                <h4 className="text-center">
                Teile als Lektor dein Fachwissen und Dienstleistung mit aufstrebenden Schriftstellern
                  oder finde einzigartige Geschichten als Testleser. Deine Leseeindrücke sind 
                  von unschätzbarem Wert für Autoren.
                </h4>
              </div>
            <div className="col-sm-1  text-white  px-40"></div>
            <div className="col-sm-4 lg:mb-0 relative max-lg:hidden">
                <img src={Pic1} alt="Landing Page" className="landingCard w-full h-full  object-cover scale-75 z-10" style={{ maxHeight: 180,  marginTop: '-20%', marginBottom: '-20%' }} />
              </div>
            </div>
          </div>
        </div>

        <div className="compThreeBGColor py-10 mb-10 max-xl:mt-10 xl:mt-20" style={{   maxHeight: 200, minHeight: 60 }}>
          <div className=" pl-5 pr-12 px-4">
            <div className="flex flex-col lg:flex-row w-full items-center justify-center">
              <div className="col-sm-4 lg:mb-0 relative max-lg:hidden">
                <img src={Pic4} alt="Landing Page" className="landingCard w-full h-full  object-cover scale-75 z-10" style={{ maxHeight: 180,  marginTop: '-20%', marginBottom: '-20%' }} />
              </div>
            <div className="col-sm-1  text-white  px-40"></div>
              <div className="col-sm-8  text-white  px-40">
                <h4 className="text-center">
                Verleihe als Cover-Designer Manuskripten ein unverwechselbares Antlitz.
		            Ein Kunstwerk, welches beim Anblick schon Leselust macht.
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="compThreeBGColorReverse py-10 mb-80 max-xl:mt-10 xl:mt-20" style={{   maxHeight: 200, minHeight: 60 }}>
          <div className=" pl-5 pr-12 px-4">
            <div className="flex flex-col lg:flex-row w-full items-center justify-center">
            <div className="col-sm-8  text-white  px-40">
                <h4 className="text-center">
                Als Verlag entdeckst du vielversprechende Manuskripte und neue Talente.
		            Eine Erweiterung deines Pools an aufstrebenden Autoren.
                </h4>
              </div>
            <div className="col-sm-1  text-white  px-40"></div>
            <div className="col-sm-4 lg:mb-0 relative max-lg:hidden">
                <img src={Pic3} alt="Landing Page" className="landingCard w-full h-full  object-cover scale-75 z-10" style={{ maxHeight: 180,  marginTop: '-20%', marginBottom: '-20%' }} />
              </div>
            </div>
          </div>
        </div>

</div>



  
  );
}

export default CompThreePic; 
export { default as CompThreePic } from './CompThreePic';