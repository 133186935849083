import './OthersProfileBookView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';
import {Link} from 'react-router-dom';

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';
import {ShortTimestampFormatter} from '../../tools/ShortTimestampFormatter.js';

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { FaPencilAlt } from "react-icons/fa";
import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";

import he from 'he';
import DOMPurify from 'dompurify';

function IconWithTooltip2({ icon, tooltipText }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {React.cloneElement(icon, {
        //style: { fontSize: '' },
        title: isHovered ? tooltipText : '',
      })}
    </div>
  );
}

  function DisplayHtml({ htmlContent }) {   
    //const escapedHtml = he.escape(htmlContent);  only html visible and not in formated way visible!
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    );
}  



const OthersProfileBookView = ({ getUserProfileType, getCurrentUserProfileName, bookState, setBookState, baseUrl, baseUrl2 }) => {


///////////////////////////////////////////////////////////////////////
    ////    Code for show Profile ...    //////////////////
    /////////////////////////////////////////////////////////////////////


    const [getSingleEntityData,setSingleEntityData] = useState([]);                                                                   
    const [getEntityRatings,setEntityRatings] = useState([]);                     
    const [getSingleEntityRating,setSingleEntityRating] = useState([]); 
    
    const [imageSrc, setImageSrc] = useState([]);
    const [getFavoriteHrefClick, setFavoriteHrefClick] = useState(false);
    const [getUpDownRightHrefClick, setUpDownRightHrefClick] = useState(false);   
    const [getAddRatingBtnClick, setAddRatingBtnClick] = useState(false);
    const [ratingText, setRatingText] = useState(''); 

    const [getProfileType, setProfileType  ] = useState('Empty');

    const [showAreYouSure, setShowAreYouSure] = useState(false);
    const [ratingScoreAverage, setRatingScoreAverage] = useState();

    const [getCoverImg, setCoverImg] = useState('');
    
    const [expandedTexts, setExpandedTexts] = useState({});

    const [getRightsData, setRightsData] = useState([]);
    const [getfilesDownloadSrc, setfilesDownloadSrc] = useState('');


    const [prevFileName, setPrevFileName,  ] = useState('');
    const [prevBookFileName, setPrevBookFileName,  ] = useState('');
    const [getBookFileName, setBookFileName,  ] = useState('');
    const [getFileName, setFileName,  ] = useState('');
    const [selectedBookFile, setSelectedBookFile] = useState(null);

    const [getBelongName, setBelongName,  ] = useState([]);
    const [getBookFileData, setBookFileData] = useState(null);
    
    const [getFileSizeToBig, setFileSizeToBig] = useState(false);
    const [getFileTypeWrong, setFileTypeWrong] = useState(false);

    const [getBookUploadSuccess, setBookUploadSuccess] = useState(false);
    const [renderBookFile, setRenderBookFile] = useState(false);
    const [getUploadFile, setUploadFile] = useState(false);

    const [getLoggedInProfileData, setLoggedInProfileData] = useState([]);
    const [getSingleUserProfileEntityData, setSingleUserProfileEntityData] = useState([]);

    const [render, setRender] = useState(false);
      
    const [minusPlusFavorit, setMinusPlusFavorit] = useState(<><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
    
    const [ratingScore, setRatingScore] = useState('');

    const [getAllAuthorData, setAllAuthorData] = useState([]);
    
    const [getTrueGenreData, setTrueGenreData] = useState([]);

    const [error, setError] = useState(null);
    
    const toggleTextExpansion = (createdByProfileName) => {
      setExpandedTexts((prevExpandedTexts) => ({
          ...prevExpandedTexts,
          [createdByProfileName]: !prevExpandedTexts[createdByProfileName],
      }));
  };

  const getSingleUserProfileEntity = async () => {

    try {
        const addNewEntityData = {
          entityName: getCurrentUserProfileName,
        };

        const response = await api.get(`/api/userProfile/${addNewEntityData.entityName}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
        });

        setSingleUserProfileEntityData(response.data);

    } catch (error) {
        handleError('Error fetching single Entity data:', error);
    }
};

    const getSingleEntity = async () => {
        try {
            const addNewEntityData = {
              entityName: getCurrentUserProfileName,
            };
    
            const response = await api.get(`/api/bookProjects/${addNewEntityData.entityName}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });

            const response2 = await api.get(`/api/authors`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });

          if(response2.data != null){
            setAllAuthorData(response2.data);
           //console.log('Authors: ', response2.data);
           // console.log('AuthorsId: ', response2.data[0].id);
          }
    
            if(response.data != null){
            setSingleEntityData(response.data);
            const singleEntity = response.data;
            showSingleRatings(singleEntity.title);

	    setBookState(false);

      const filteredGenres = Object.keys(singleEntity.genresIds[0])
      .filter(key => key !== '_id' && singleEntity.genresIds[0][key] === true);
      //console.log('trueGenres: ', filteredGenres);
      setTrueGenreData(filteredGenres);

          }

          

        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    };

    const getEntityRights = async () => {
        try {
            const addNewEntityData = {
              entityName: getCurrentUserProfileName,
            };
    
            const response = await api.get(`/api/userProfile/${addNewEntityData.entityName}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            if(response.data != null){
            	setRightsData(response.data.allRights[0]);
              //console.log('response.data.allRights[0]: ', response.data.allRights[0]);
	    }
          
        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    };
    
    
    const showSingleRatings = async (entityName) => {
        try {
            const response = await api.get(`/api/ratings/createdFor/${entityName}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            setSingleEntityRating(response.data);
    
            const sumOfRatingScores = response.data.reduce((sum, item) => sum + item.ratingScore, 0);
            const averageRatingScore = sumOfRatingScores / response.data.length;
            const roundedAverageRatingScore = averageRatingScore.toFixed(1);
    
            if (response.data.length > 0) {
                setRatingScoreAverage(parseFloat(roundedAverageRatingScore));
            } else {
                setRatingScoreAverage('');
            }
        } catch (error) {
            handleError('Error showing single ratings:', error);
        }
    };
    
    const changeFavorite = async () => {
      try {
          setFavoriteHrefClick(true);

          const userProfileType = localStorage.getItem('currentProfileType');

          if(userProfileType === 'Author'){
            setProfileType('authors');
          }
          if(userProfileType === 'Editor'){
            setProfileType('editors');
          } 
          if(userProfileType === 'Testreader'){
            setProfileType('testReaders');
          }
          if(userProfileType === 'Publisher'){
            setProfileType('publishers');
          }
          if(userProfileType === 'CoverDesigner'){
            setProfileType('coverDesigners');
          }
            
          
      } catch (error) {
          handleError('Error changing favorite:', error);
      }
  };

  useEffect(() => {
    
    const addNewFavoriteData = {
      entityName: localStorage.getItem('profileName'),
      favoriteName: getCurrentUserProfileName,
  };

    // Make API call when profileType changes
    if (getProfileType && getProfileType !== '' && getFavoriteHrefClick === true) {
      const fetchData = async () => {
        try {

          const response = await api.post(`/api/${getProfileType}/changeFavorite`, addNewFavoriteData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
          //console.log('Favorite:', response.data);
          setLoggedInProfileData(response.data);

          const isFavorite = response.data.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);
        
          if (isFavorite) {
            setMinusPlusFavorit( <><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
          } else {
            setMinusPlusFavorit( <><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
          }
          
        } catch (error) {
          handleError('Error changing favorite:', error);
        }
      };
      fetchData();
    }
             

  }, [getProfileType, getFavoriteHrefClick]);  
    
    const addRating = async (e) => {
        e.preventDefault();
    
        const { target } = e;
    
        const addNewRatingData = {
            ratingScore: target.ratingScore.value,
            ratingText: target.ratingText.value,
            createdByProfileName: localStorage.getItem('profileName'),
            isRatingForProfileName: getCurrentUserProfileName,
        };
    
        console.log('addNewFavoriteData:', addNewRatingData);
    
        setAddRatingBtnClick(false);
    
        try {
            const response = await api.post(`/api/ratings/createRating`, addNewRatingData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
            });
    
            console.log('Rating:', response.data);
        } catch (error) {
            handleError('Error adding rating:', error);
        }
    };
    

    const handleRatingTextChange = (event) => {
        setRatingText(event.target.value);
    };


    const downloadFile = (filename) => {
      const getMimeType = (filename) => {
        const extension = filename.split('.').pop();
        switch (extension) {
          case 'pdf':
            return 'application/pdf';
          case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          case 'doc':
            return 'application/msword';
          case 'txt':
            return 'text/plain';
          case 'rtf':
            return 'application/rtf';
          case 'md':
            return 'text/markdown';
          default:
            return 'application/octet-stream';
        }
      };
             
    
      fetch(`${baseUrl}/api/files/download/${filename}`, {
        method: 'GET',
        headers: {
          'Content-Type': getMimeType(filename),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error('File download failed');
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('Error:', error));
    };

    const handleBookFileChange = (e) => {
      try {
        setPrevBookFileName(getFileName);
        const filename = e.target.files[0].name;
        setBookFileName(filename);     
        setSelectedBookFile(e.target.files[0]);
        setBookUploadSuccess(false);
       /* const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]); */
  
        //setRenderBookFile(!renderBookFile);
      } catch (error) {
        handleError('Error handling file change:', error);
      }
    };

    const handleBookFileUpload = async (e) => {
      try {
        e.preventDefault();
        const fileInput = e.target.querySelector('input[type="file"]');
        const file = fileInput.files[0];
    
        if (!file) {
          handleError('No file selected');
          return;
        }
    
        const allowedFileTypes = ['application/pdf',
        'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (!allowedFileTypes.includes(file.type)) {
          setFileTypeWrong(true);
          handleError('Invalid file type');
          return;
        }
    
        const maxSize = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxSize) {
          setFileSizeToBig(true);
          handleError('File size exceeds the limit of 5 MB');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uploadName', localStorage.getItem('profileName'));
        formData.append('belongName', getCurrentUserProfileName);
    
        const response = await api.post('/api/files/uploadBook', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
      setTimeout(() => {
        // Update the state to trigger a re-render
        fetchBookFile();
      }, 1000); // Delay in milliseconds (adjust as needed)
  
      if(prevBookFileName !== null && prevBookFileName !== '' && !prevBookFileName.isEmpty){
          const response2 = await api.delete(`/api/files/delete/${prevBookFileName}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
       }

      } catch (error) {
        handleError('Error uploading file:', error);
      }
    };

    const fetchBookFile = async () => {
      try {
        const newBookFileData = await api.get(`/api/files/${getBookFileName}`);
        console.log('newBookFileData: ', newBookFileData);
        console.log('newBookFileData.fileName: ', newBookFileData.fileName);
        if(newBookFileData !== null && !newBookFileData.isEmpty){
            setBookFileData(newBookFileData.data);
            setBookUploadSuccess(true);
            console.log('newBookFileData.fileName: ', newBookFileData.data.fileName);
        }
      } catch (error) {
        handleError('Error fetching image:', error);
      }
    };


  const changeFavoriteButton = async () => {

    const userProfileType = localStorage.getItem('currentProfileType');

          if(userProfileType === 'Author'){
            setProfileType('authors');
          }
          if(userProfileType === 'Editor'){
            setProfileType('editors');
          } 
          if(userProfileType === 'Testreader'){
            setProfileType('testReaders');
          }
          if(userProfileType === 'Publisher'){
            setProfileType('publishers');
          }
          if(userProfileType === 'CoverDesigner'){
            setProfileType('coverDesigners');
          }

        try {
          if(getProfileType !== ''){
      const response2 = await api.get(`/api/${getProfileType}/${localStorage.getItem('profileName')}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}`}}
      );
   
      //console.log('Response2 Data:', response2.data);
      setLoggedInProfileData(response2.data);

      const isFavorite = response2.data.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);
        
      if (isFavorite) {
        setMinusPlusFavorit( <><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
      } else {
        setMinusPlusFavorit( <><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
      }
    }
    } catch (error) {
      handleError('Error changing favorite2:', error);
    }

  }

    const renderFavorite = () => {
      // Check if favoriteIds exist and are not empty
      if (getLoggedInProfileData && getLoggedInProfileData.favoriteIds && getLoggedInProfileData.favoriteIds.length > 0) {
        // Check if the current user's profile name is in the favoriteIds
        const isFavorite = getLoggedInProfileData.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);
        //console.log('isFavorite: ', isFavorite);
        if (isFavorite) { setMinusPlusFavorit( <><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
        } else {  setMinusPlusFavorit( <><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
        }
      } else { // Handle the case where favoriteIds is empty or undefined
        setMinusPlusFavorit('Lade Favorit Status');
        reRenderTwo();
      }
    };

    const renderFavoriteButton = () => {
      if (getLoggedInProfileData && getLoggedInProfileData.favoriteIds && getLoggedInProfileData.favoriteIds.length > 0) {
        const isFavorite = getLoggedInProfileData.favoriteIds.some(favorite => favorite.userProfileName === getCurrentUserProfileName);    
        if (isFavorite) { setMinusPlusFavorit( <><FaPlusSquare className='mr-2 mt-1.5'/> Favorit</>);
        } else {  setMinusPlusFavorit( <><FaMinusSquare className='mr-2 mt-1.5'/> Favorit</>);
        }
      } else { // Handle the case where favoriteIds is empty or undefined
        setMinusPlusFavorit('Lade Favorit Status');
        reRenderTwo();
      }
    };



    const handleRatingScoreInputChange = (event) => {
      const { value } = event.target;
      // Allow only numbers and a single decimal point
      const regex = /^(\d+(\.\d{0,1})?)?$/;
  
      if (regex.test(value) && (value === '' || parseFloat(value) <= 100)) {
        setRatingScore(value);
      }
    };

    const changeOtherProfileName = (otherProfileName, otherProfileType) => {
      localStorage.setItem('siteProfile', otherProfileName);
      localStorage.setItem('otherProfileType', otherProfileType);
  };
    

    const reRenderTwo = () => {
     setTimeout(() => {
        setRender(!render);            
      }, 300);
    }

    useEffect(() => {
      changeFavoriteButton();
      getSingleUserProfileEntity();
      getEntityRights();
  }, [render]);

  useEffect(() => {
      const fetchData = async () => {
        try {
            if (getUserProfileType === 'Author') {
                const entityData = getSingleEntity();
                setSingleEntityData(entityData);
                console.log('Authors:', entityData);
            }
        } catch (error) {
            handleError('Error adding rating:', error);
        }
    };
        getEntityRights(); 
        fetchData();
      getSingleUserProfileEntity();
      setTimeout(() => { 
        renderFavorite();           
      }, 3000);
  }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (getUserProfileType === 'Book') {
                    const entityData = getSingleEntity();
                    if(entityData != null){
                    setSingleEntityData(entityData);
                    console.log('Books:', entityData);
                    }
                }
            } catch (error) {
                handleError('Error adding rating:', error);
            }
        };
        getEntityRights();
        fetchData();
        setImageSrc(`${baseUrl}/api/files/image/`);
	      setfilesDownloadSrc(`${baseUrl}/api/files/image/`);
    }, [getUserProfileType]);


      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

    	///////////////////////////////// Logout redirect //////////////////////////7

        const navigate2 = useNavigate();

        useEffect(() => {
          LogoutRedirectCheck(navigate2);
          setFavoriteHrefClick(false);
        }, [getFavoriteHrefClick, getUploadFile, getAddRatingBtnClick, getBookUploadSuccess ]);
        
        ///////////////////////////////// Logout redirect //////////////////////////7





        return (
          <div className="OthersProfileBookView ">
            <div className={`${getAddRatingBtnClick ? 'blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden' : ''} `}></div>
        
            {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
           
        <><div className="row row-col-12 text-center m-4">
            {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
            {getFileSizeToBig && (<div><p style={{ color: 'red' }}>Deine Datei  berschreitet 5 MB an Gr  e!</p></div>)}
            {getFileTypeWrong && (<div><p style={{ color: 'red' }}>Deine Datei muss vom Typ PNG oder JPEG sein!</p></div>)}
          </div></>
        

          {getAddRatingBtnClick && (
            <div className='blurPage text-center fixed top-0 left-0 w-full h-full flex justify-center items-center z-30'>
              <div className='mx-2 FormStyling' style={{ minWidth: '350px', maxWidth: '1000px', maxHeight: '90vh' }}>
                <div><button className='buttonTwo btnChange p-2' onClick={() => setAddRatingBtnClick(false)}>zurück</button></div>
                <form onSubmit={addRating} method='POST' className='w-full justify-center items-center text-center'>
                  <div className='w-full justify-center items-center text-center'>
                    <h3 className='text-center'>Rating-Daten</h3>
                    <div className='row'>
                      <div className='text-center'>
                        <h4 className='text-center'>Dein Rating-Wert von 0.0 - 100.0</h4>
                        <input
                                className='FormStyling text-center rounded-lg text-white compThreeBGColor'
                                placeholder='Wert...'
                                name='ratingScore'
                                value={ratingScore}
                                onChange={handleRatingScoreInputChange}
                                style={{ minWidth: '100px', maxWidth: '100px' }}
                              />
                      </div>
                    </div>
                    <div className='row FormStyling mx-10 px-10'>
                      <div className='text-center' style={{ minWidth: '300px', minHeight: '300px', maxWidth: '1000px' }}>
                        <div className='row flex-grow-1 textAreaOne'>
                          <h4 className='text-center'>Kommentar</h4>
                          <textarea
                            className='FormStyling w-full flex-grow-1 rounded-lg text-white compThreeBGColor'
                            placeholder='Dein Text...'
                            name='ratingText'
                            style={{ overflowY: 'auto', overflowX: 'auto', maxHeight: '200px', maxWidth: '900px', minHeight: '180px', minWidth: '330px' }}
                            value={ratingText}
                            onChange={handleRatingTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <button type='submit' className='btnChange p-3 mb-10'>Bestätigen</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}


        
<div className=' w-full max-sm:pl-10 max-sm:pr-0'>
              {getUserProfileType === "Book" && (
                <div className="">
                  <div className="col">
                    {getSingleEntityData && (
                      <>
                        <div className="row">
                        </div>
                        <div className="row profileDataCard2  paddingOne">
                          <div className="row titleRowBackgroundcolor white-glassmorphism-card ">
                          <div className="col col-sm-12 max-sm:text-center">
                            {getSingleEntityData.authorIds && getSingleEntityData.authorIds.length > 0 && getSingleEntityData.authorIds[0] && (
                              <div>
                              {getAllAuthorData && getAllAuthorData.map(( author, index) => ( 
                                <div key={author.id.timestamp + index}> 
                                 { author.id.timestamp === getSingleEntityData.authorIds[0].timestamp && (
                                      <a
                                      href={`${baseUrl2}/profile?othProfileName=${author.authorName}&othProfileType=Author`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() => changeOtherProfileName(author.authorName, 'Author')}
                                    >
                                        <h2 className="FormStyling md:text-left  titleTextColor">Autor: {author.authorName}</h2>
                                      </a>
                                  )}
                                    </div>
                              ))}
                              </div>
                            )}
                            </div>
                            </div>
      <div className='row white-glassmorphism-card pb-3' >
                     <div className="col col-sm-6 sm:col-sm-6  md:justify-start md:col-sm-6 justify-center max-sm:text-center flex md:col-sm-6 md:mb-0">
                                 <h4 className="md:text-left titleTextColor"> | {getSingleEntityData.follower} Follower</h4>
                            </div>
                        
			{ getSingleEntityData.bookFilesIds && getSingleEntityData.bookFilesIds.length > 0 && getSingleEntityData.bookFilesIds[0].uploadedByProfileName !== localStorage.getItem('profileName') && (
			  <div className='col col-sm-6 sm:col-sm-6 md:justify-end md:col-sm-6 justify-center flex max-md:pb-5'>
			    <div className="mx-2 FormStyling text-right">
                              <button className="buttonTwo btnChange p-2 flex hover:scale-105" onClick={()=> {changeFavorite(); renderFavoriteButton(); }}>{minusPlusFavorit}</button>
                            </div> 
                              { getRightsData && getRightsData.toRate && getRightsData.toRate.map((rightFor, index) => (
                                            <div key={rightFor.timestamp}>
                                                  {rightFor.timestamp.toString() === localStorage.getItem(`userProfileIdTimestamp`)  && (
                                                     <div className="mx-2 FormStyling text-right">
                                                        <IconWithTooltip2 icon={     <button className="buttonTwo btnChange p-2 flex hover:scale-105" onClick={() => setAddRatingBtnClick(true)}><FaPlusSquare className='mr-2 mt-1.5' />Rating</button>
                                                               } tooltipText="Füge dem Profil ein Rating von dir hinzu." />
                                                         
                                                                       </div>
                                                   )}
                                          </div>
                                    ))}
			     </div>
			   )}

                            </div>
                         


                          <hr className='horizontalLine' />
        
                          <div className="row mainRowBackgroundcolor sm:flex white-glassmorphism-card pt-3 pb-3">
                            <div className='col col-sm-6'>
                              <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                                <div className='max-md:mb-20  max-lg:w-full' style={{ minWidth: 200 }}>
                                  <h3 className="FormStyling ">Titel</h3>
                                  <h4 className="FormStyling ">{getSingleEntityData.title ? getSingleEntityData.title : ' - '}</h4>
                                  <br></br>
                                  <h3 className="FormStyling ">Normseiten</h3>
                                  <h4 className="FormStyling ">{getSingleEntityData.pages ? getSingleEntityData.pages : ' - '}</h4>
                                </div>
                              </div>
                              <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                                <div className='max-sm:mb-10 max-lg:w-full' style={{ minWidth: 200 }}>
                                  <h3 className="FormStyling">Untertitel</h3>
                                  <h4 className="FormStyling">{getSingleEntityData.subtitle ? getSingleEntityData.subtitle : ' - '}</h4>
                                  <br></br>
                                  <h3 className="FormStyling">Genre</h3>
                                  <div className='sm:flex'>
                                      { getTrueGenreData && getTrueGenreData.map((key, index) => (
                                          <h4 key={key} className="FormStyling capitalize">{key}{getTrueGenreData.length > (index+1)?", ":""}</h4>
                                        ))}
                                  </div> 
                              </div>
                              </div>
                            </div>
                            <div className="col col-sm-6 text-left ml-5 mainRowBackgroundcolor ">
                            <h3 className="FormStyling">Klappentext</h3> 
                            <DisplayHtml htmlContent={getSingleEntityData.jacketText} style={{ wordWrap: 'break-word' }} />
                          </div>
                          </div>
                        </div>
                      </>
                    )}
        
                    {((getSingleEntityData.tableOfContentText && getSingleEntityData.tableOfContentText.length > 0) || (getSingleEntityData.coverImgName && getSingleEntityData.coverImgName.trim() !== "" )) && (
                      <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                          <h2 className="FormStyling text-left titleTextColor">Inhaltsverzeichnis</h2>
                        </div>
                        <hr className='horizontalLine' />
                        <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-7 text-left mainRowBackgroundcolor ml-20 mt-4" style={{ wordWrap: 'break-word' }}>
                              <DisplayHtml htmlContent={getSingleEntityData.tableOfContentText} />
                          </div>
                          <div className='col col-sm-4 mb-5'>
                            <div className='col-sm-6 flex text-center justify-center items-center mt-4 '>
                              <div className="profileImg  my-3  ">
                                <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }}>
                                  <img className="img-fluid profileImg3" src={`${imageSrc}${getSingleEntityData.coverImgName}`} alt="Dein Coverbild"
                                    style={{ maxWidth: '240px', maxHeight: '388px', minWidth: '240px', minHeight: '388px' }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}


{(getSingleEntityData.description && getSingleEntityData.description.length > 0) && (
                      <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                          <h2 className="FormStyling text-left titleTextColor">Genauere Beschreibung</h2>
                        </div>
                        <hr className='horizontalLine' />
                        <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-12 text-left ml-5 mainRowBackgroundcolor" style={{ wordWrap: 'break-word' }}>
                              <DisplayHtml htmlContent={getSingleEntityData.description} />
                          </div>
                      </div>
                  </div>
                    )}

{/*}
        
                    { getSingleEntityData.bookFilesIds && getSingleEntityData.bookFilesIds.length > 0 && (

                        

                       <>  { getSingleEntityData.bookFilesIds.map((bookFiles, index) => (
                            
                           <>   { bookFiles.uploadedByProfileName === 'ReadingSample' && (
                      
                      
                      <div key={bookFiles}className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                        <div className="col-sm-12">
                           <div className="col col-sm-8">
                              <h2 className="FormStyling text-left titleTextColor -ml-12">Leseprobe</h2>
                          </div>
                          </div>
                        </div>
                        <div className="row white-glassmorphism-card py-2 text-left">
                        <div className="col col-sm-12 sm:flex  justify-between">
                            <div className="col-sm-2 max-sm:hidden"><h3 className="topRowStyling">Typ</h3></div>
                            <div className="col-sm-5  max-sm:hidden"><h3 className="topRowStyling">Titel</h3></div>
                            <div className="col-sm-3   max-sm:hidden"><h3 className="topRowStyling">Uploaded am</h3></div>
                            <div className='col-sm-2 textBackgroundColor  max-sm:hidden'>Download</div>                      
                          </div>
                        </div>
                        <hr className='horizontalLine' />
                        
                        <div  className="mainRowBackgroundcolor white-glassmorphism-card">
                        <div>

                       <div className='row  white-glassmorphism-card mainRowBackgroundcolor'>
                                <div className="col col-sm-12 sm:flex text-left justify-between mainRowBackgroundcolor ">
                              
                      
                           <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h4 className="FormStyling">{bookFiles.uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h4></div>
                              <div className="col col-sm-5"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h4 className="FormStyling">{bookFiles.fileName}</h4></div>
                              <div className="col col-sm-3"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h4 className="FormStyling"><ShortTimestampFormatter timestamp={bookFiles.filesUploadedAt} /></h4></div>   
                              <button className="col-sm-2 FormStyling pb-3  max-sm:ml-6" onClick={()=> downloadFile(bookFiles.fileName)}>Download</button>         
                            

                             </div>
                            </div>
                          
                          </div>
   
                        </div>                    
                    </div>
                              )}  </>
                              ))}  </>
                    )}
*/}
                    
        
              { getSingleEntityData.bookFilesIds && getSingleEntityData.bookFilesIds.length > 0 && (
                       
                <>                

               <> { ( getSingleEntityData.bookFilesIds.length === 2) && (
                            
                        
                             
                    <>{ (getSingleEntityData.bookFilesIds[0].uploadedByProfileName === 'ReadingSample'
                      ||  getSingleEntityData.bookFilesIds[1].uploadedByProfileName === 'ReadingSample' ) && (
                       
                       
                       <div className="row profileDataCard2  paddingOne">
                         <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                         <div className="col-sm-12">
                            <div className="col col-sm-8">
                               <h2 className="FormStyling text-left titleTextColor -ml-12">Buchdatei und Leseprobe</h2>
                           </div>
                           </div>
                         </div>
                         <div className="row white-glassmorphism-card py-2 text-left">
                         <div className="col col-sm-12 sm:flex  justify-between">
                             <div className="col-sm-2 max-sm:hidden"><h3 className="topRowStyling">Typ</h3></div>
                             <div className="col-sm-5  max-sm:hidden"><h3 className="topRowStyling">Titel</h3></div>
                             <div className="col-sm-3   max-sm:hidden"><h3 className="topRowStyling">Uploaded am</h3></div>
                             <div className='col-sm-2 textBackgroundColor  max-sm:hidden'>Download</div>                      
                           </div>
                         </div>
                         <hr className='horizontalLine' />
                         

                         <div  className="mainRowBackgroundcolor white-glassmorphism-card">
                         <div>
 
                          { getSingleEntityData.bookFilesIds.map((bookFiles, index) => (

                            <div key={bookFiles} className={`row  white-glassmorphism-card ${!getUploadFile && (index === 1 || getSingleEntityData.bookFilesIds.length === 1 )?'mainRowBackgroundcolor':''}`}>
                                <div className="col col-sm-12 sm:flex text-left justify-between mainRowBackgroundcolor ">

                                { bookFiles.uploadedByProfileName === 'ReadingSample' &&  (
                                <div className='w-full'>
                                     <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h4 className="FormStyling">{bookFiles.uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h4></div>
                                      <div className="col col-sm-5"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h4 className="FormStyling">{bookFiles.fileName}</h4></div>
                                      <div className="col col-sm-3"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h4 className="FormStyling"><ShortTimestampFormatter timestamp={bookFiles.filesUploadedAt} /></h4></div>   
                                      <button className="col-sm-2 FormStyling pb-3  max-sm:ml-6" onClick={()=> downloadFile(bookFiles.fileName)}>Download</button>         
                                  
                                    
                                  </div>
                                )}
                            

                              { bookFiles.uploadedByProfileName !== 'ReadingSample' && getRightsData.toDownLoad && getRightsData.toDownLoad.map((rightsFor, index) => (
                                <div key={rightsFor.timestamp.toString()}  className={`w-full ${rightsFor.timestamp.toString() === localStorage.getItem(`userProfileIdTimestamp`)?'':'hidden'}`}>
         
                                  <div  className='w-full'>
                                    <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h4 className="FormStyling">{bookFiles.uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h4></div>
                                      <div className="col col-sm-5"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h4 className="FormStyling">{bookFiles.fileName}</h4></div>
                                      <div className="col col-sm-3"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h4 className="FormStyling"><ShortTimestampFormatter timestamp={bookFiles.filesUploadedAt} /></h4></div>   
                                      <button className="col-sm-2 FormStyling pb-3  max-sm:ml-6" onClick={()=> downloadFile(bookFiles.fileName)}>Download</button>         
                                  </div>  
                                    
                                  </div>
                                ))}
                             </div>
                            </div>
                          ))}
                          </div> 
                           
                           </div>
    
                         </div>                    
                     
                               )}  </>
                               )}  </>
                     
                     

                     <> { getSingleEntityData.bookFilesIds[0].uploadedByProfileName === 'ReadingSample' && 
                       getSingleEntityData.bookFilesIds.length <= 1 && (
                    
                       
                       
                       <div className="row profileDataCard2  paddingOne">
                         <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                         <div className="col-sm-12">
                            <div className="col col-sm-8">
                               <h2 className="FormStyling text-left titleTextColor -ml-12">Leseprobe</h2>
                           </div>
                           </div>
                         </div>
                         <div className="row white-glassmorphism-card py-2 text-left">
                         <div className="col col-sm-12 sm:flex  justify-between">
                             <div className="col-sm-2 max-sm:hidden"><h3 className="topRowStyling">Typ</h3></div>
                             <div className="col-sm-5  max-sm:hidden"><h3 className="topRowStyling">Titel</h3></div>
                             <div className="col-sm-3   max-sm:hidden"><h3 className="topRowStyling">Uploaded am</h3></div>
                             <div className='col-sm-2 textBackgroundColor  max-sm:hidden'>Download</div>                      
                           </div>
                         </div>
                         <hr className='horizontalLine' />
                         
                         <div  className="mainRowBackgroundcolor white-glassmorphism-card">
                         <div>
 
                        <div className='row  white-glassmorphism-card mainRowBackgroundcolor'>
                                 <div className="col col-sm-12 sm:flex text-left justify-between mainRowBackgroundcolor ">
                               
                       
                            <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h4 className="FormStyling">{getSingleEntityData.bookFilesIds[0].uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h4></div>
                               <div className="col col-sm-5"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h4 className="FormStyling">{getSingleEntityData.bookFilesIds[0].fileName}</h4></div>
                               <div className="col col-sm-3"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h4 className="FormStyling"><ShortTimestampFormatter timestamp={getSingleEntityData.bookFilesIds[0].filesUploadedAt} /></h4></div>   
                               <button className="col-sm-2 FormStyling pb-3  max-sm:ml-6" onClick={()=> downloadFile(getSingleEntityData.bookFilesIds[0].fileName)}>Download</button>         
                             
 
                              </div>
                             </div>
                           
                           </div>
    
                         </div>                    
                     </div>
                               )}  </>
                     
                     
                     
                     
                       <> { getSingleEntityData.bookFilesIds[0].uploadedByProfileName !== 'ReadingSample' && 
                       getSingleEntityData.bookFilesIds.length <= 1 && (
                            
                         <div > {  getRightsData.toDownLoad && getRightsData.toDownLoad.map((rightsFor, index) => (
                            <div key={rightsFor.timestamp.toString()} >
                                {rightsFor.timestamp.toString() === localStorage.getItem(`userProfileIdTimestamp`) && ( 
                      
                         <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                        <div className="col-sm-12">
                           <div className="col col-sm-8">
                              <h2 className="FormStyling text-left titleTextColor -ml-12">Buchdatei</h2>
                          </div>
                          </div>
                        </div>
                        <div className="row white-glassmorphism-card py-2 text-left">
                        <div className="col col-sm-12 sm:flex  justify-between">
                            <div className="col-sm-2 max-sm:hidden"><h3 className="topRowStyling">Typ</h3></div>
                            <div className="col-sm-5  max-sm:hidden"><h3 className="topRowStyling">Titel</h3></div>
                            <div className="col-sm-3   max-sm:hidden"><h3 className="topRowStyling">Uploaded am</h3></div>
                            <div className='col-sm-2 textBackgroundColor  max-sm:hidden'>Download</div>                      
                          </div>
                        </div>
                        <hr className='horizontalLine' />
                        
                        <div  className="mainRowBackgroundcolor white-glassmorphism-card">
                        <div>

                       <div className='row  white-glassmorphism-card mainRowBackgroundcolor'>
                                <div className="col col-sm-12 sm:flex text-left justify-between mainRowBackgroundcolor ">
                              
                      
                           <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h4 className="FormStyling">{getSingleEntityData.bookFilesIds[0].uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h4></div>
                              <div className="col col-sm-5"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h4 className="FormStyling">{getSingleEntityData.bookFilesIds[0].fileName}</h4></div>
                              <div className="col col-sm-3"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h4 className="FormStyling"><ShortTimestampFormatter timestamp={getSingleEntityData.bookFilesIds[0].filesUploadedAt} /></h4></div>   
                              <button className="col-sm-2 FormStyling pb-3  max-sm:ml-6" onClick={()=> downloadFile(getSingleEntityData.bookFilesIds[0].fileName)}>Download</button>         
                            

                             </div>
                            </div>
                          
                          </div>
   
                        </div> 
                        </div>                   
                                )} </div>
                        ))}  </div>
                  )}  </>
                  </>
              )} 
              

              {/*}
                    { getSingleEntityData.bookFilesIds && getSingleEntityData.bookFilesIds.length > 0 && (
                      <div className="row profileDataCard2  paddingOne">
                        <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                        <div className="col-sm-12">
                           <div className="col col-sm-8">
                              <h2 className="FormStyling text-left titleTextColor -ml-12">Buchdatei und Leseprobe</h2>
                          </div>
                        
                         {/*} {getRightsData.toUpLoad && getRightsData.toUpLoad.map((rightsFor, index) => (
                              <div key={rightsFor.timestamp.toString()}>
                                {rightsFor.timestamp.toString() === localStorage.getItem(`userProfileIdTimestamp`) && (  
                                  <div className="col col-sm-4 text-right">  
                                      <button className="buttonTwo btnChange p-2 mt-3" onClick={()=> {setUploadFile(!getUploadFile); setBookUploadSuccess(false);}}><FaPencilAlt className='mr-2 mt-1.5' />Upload File</button>         
                               </div>
                                )}
                              </div>
                            ))}   
                          </div>
                        </div>
                        <div className="row white-glassmorphism-card py-2 text-left">
                        <div className="col col-sm-12 sm:flex  justify-between">
                            <div className="col-sm-2 max-sm:hidden"><h3 className="topRowStyling">Typ</h3></div>
                            <div className="col-sm-5  max-sm:hidden"><h3 className="topRowStyling">Titel</h3></div>
                            <div className="col-sm-3   max-sm:hidden"><h3 className="topRowStyling">Uploaded am</h3></div>
                            <div className='col-sm-2 textBackgroundColor  max-sm:hidden'>Download</div>                      
                          </div>
                        </div>
                        <hr className='horizontalLine' />
                        
                        <div  className="mainRowBackgroundcolor white-glassmorphism-card">
                        <div>
                        { getSingleEntityData.bookFilesIds.map((bookFiles, index) => (

                            <div key={bookFiles} className={`row  white-glassmorphism-card ${!getUploadFile && (index === 1 || getSingleEntityData.bookFilesIds.length === 1 )?'mainRowBackgroundcolor':''}`}>
                                <div className="col col-sm-12 sm:flex text-left justify-between mainRowBackgroundcolor ">

                              { bookFiles.uploadedByProfileName !== 'ReadingSample' && getRightsData.toDownLoad && getRightsData.toDownLoad.map((rightsFor, index) => (
                                <div key={rightsFor.timestamp.toString()}>
                                {rightsFor.timestamp.toString() === localStorage.getItem(`userProfileIdTimestamp`) && (    
                                  <>    <div className="col col-sm-2 flex"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Typ:</h3><h4 className="FormStyling">{bookFiles.uploadedByProfileName === 'ReadingSample'?'Leseprobe':'Buchdatei'}</h4></div>
                                      <div className="col col-sm-5"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Titel:</h3><h4 className="FormStyling">{bookFiles.fileName}</h4></div>
                                      <div className="col col-sm-3"><h3 className="topRowStyling mt-2 mr-2 titleTextColor sm:hidden">Uploaded am:</h3><h4 className="FormStyling"><ShortTimestampFormatter timestamp={bookFiles.filesUploadedAt} /></h4></div>   
                                      <button className="col-sm-2 FormStyling pb-3  max-sm:ml-6" onClick={()=> downloadFile(bookFiles.fileName)}>Download</button>         
                                  </>  )}
                                    
                                  </div>
                                ))}
                             </div>
                            </div>
                          ))}
                          </div>  /*}
                          {/*}
                          { getUploadFile && (
                                <div className="row mainRowBackgroundcolor text-center justify-center items-center">
                              <div className="col col-sm-12 mainRowBackgroundcolor white-glassmorphism-card ">

                                <div className="col col-sm-4 text-center justify-center mt-16 mb-10 max-md:mt-12">
                                  <div className="textAreaOne ">
                                    <form className='' onSubmit={handleBookFileUpload} method="POST" encType="multipart/form-data">
                                      <input className='w-full text-center justify-center items-center' type="file" onChange={handleBookFileChange} />
                                      <br></br>
                                      <button className="buttonOne px-4 py-2 bg-gray-600 text-white rounded mr-2">hochladen</button>
                                    </form>
                                  </div>
                                </div>
                                <div className="col col-sm-8 flex text-center justify-center items-center">
                                { getBookUploadSuccess && ( 
                                <div className="">
                                    <div>
                                      <div className="my-10" style={{ minWidth: '300px', minHeight: '50px' }}>
                                        <div><h3 className='text-center'>Erfolgreich Hochgeladen</h3></div>
                                        <div className=" " style={{ minWidth: '300px', minHeight: '60px' }}>
                                          <div className="profileImg2 bg-blue-200" style={{ maxWidth: '240px', maxHeight: '50px', minWidth: '300px', minHeight: '50px' }}>
                                            <div className='p-5 text-center'>
                                              <p><b>100%</b></p> 
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            )}    
                        </div>                    
                    </div>
                    )}
         */}
        
                    {getSingleEntityRating && (getSingleEntityRating.length > 0) && (
                      <div className="row profileDataCard2  paddingOne mb-20">
                        <div className="row row-col-3 max-md:flex  titleRowBackgroundcolor white-glassmorphism-card">
                          <div className="col md:col-sm-9 col-sm-6 w-full text-left paddingTwo">
                            <h2 className="FormStyling text-left titleTextColor">Ratings</h2>
                          </div>
                          <div className="col md:col-sm-1  col-sm-4 w-full text-right md:text-left paddingZero">
                            <h4 className="FormStyling sm:text-right">Gesamt: </h4>
                          </div>
                          <div className="col md:col-sm-2 col-sm-1 w-full text-left ml-4 paddingZero">
                            <h2 className="FormStyling text-left max-sm:-ml-14 text-left paddingZero">{ratingScoreAverage}</h2>
                          </div>
                        </div>
                        <div className="row titleRowBackgroundcolor2 max-md:flex white-glassmorphism-card">
                          <div className="col col-sm-1 max-md:hidden text-left"><h3 className="topRowStyling">Wert</h3></div>
                          <div className="col col-sm-3 max-md:hidden text-left"><h3 className="topRowStyling">Von</h3></div>
                          <div className="col col-sm-8 max-md:hidden text-left"><h3 className="topRowStyling">Kommentar</h3></div>
                        </div>
                        <hr className='horizontalLine' />
        
                        {getSingleEntityRating.map((rating, index) => (
                          <div key={rating.createdByProfileName} className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card">
                            <div className="col col-sm-1 max-md:flex text-left">
                              <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Wert:</h3>
                              <h4 className="FormStyling max-md:pl-3">{rating.ratingScore}</h4>
                            </div>
                            <div className="col col-sm-3 max-md:flex text-left">
                              <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Von:</h3>
                              <h4 className="FormStyling max-md:pl-3">{rating.createdByProfileName}</h4>
                            </div>
                            <div className="col col-sm-8 text-left">
                              <div
                                style={{
                                  maxWidth: '730px', // Set a fixed height as an example, adjust as needed
                                  overflow: 'hidden',
                                  cursor: 'pointer'
                                }}
                              >
                                <h3 className="topRowStyling md:hidden titleTextColor">Kommentar:</h3>
                                <h4
                                  className="FormStyling"
                                  onClick={() => toggleTextExpansion(rating.createdByProfileName)}
                                >
                                  {expandedTexts[rating.createdByProfileName]
                                    ? rating.ratingText // Show full text if expanded
                                    : `${rating.ratingText.substring(0, 80)}...` // Show truncated text
                                  }
                                </h4>
                              </div>
                              <hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mt-5 mb-5 ${(index === 1 ||  getSingleEntityRating.length === index +1) ?'hidden':'sm:hidden'}`}/>
                            </div>
                          </div>
                        ))}
                        <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
                      </div>
                    )}
        
        
                  </div>
                </div>
              )}
        
            </div>
          </div>
        );
        
}

export default OthersProfileBookView; 
export { default as OthersProfileBookView } from './OthersProfileBookView';