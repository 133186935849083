import './AddPublisherEmployee.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import logo from '../../images/shiny_symbol_withcircle1.png';


function AddPublisherEmployee() {


    const [getBelongName, setBelongName,  ] = useState([]);
    const [render, setRender] = useState(false);    

    const [getUserName, setUserName] = useState();
    const [getIsConfirmed, setIsConfirmed] = useState();
    const [getIntervallId, setIntervallId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [getToken, setToken] = useState();

    const [error, setError] = useState(null);


    const rerender = () => {
      setRender(true);
    };
    
    
    const register = async (e) => {
      try {
        e.preventDefault();
    
        const { target } = e;
    
        const newEntityData = {
          userName: target.userName.value,
          password: target.password.value,
          profileName: getBelongName,
        };

      
        setUserName(newEntityData.userName);
    
        const response = await api.post(`/api/v1/auth/register`, newEntityData);
        console.log('Register:', response.data.token);
    
        const newEntityData2 = {
          employeeEmail: target.userName.value,
          publisherProfileName: getBelongName,
        };

        const response2 = await api.post(`/api/publishers/addEmployee`, newEntityData2, {
          headers: { Authorization: `Bearer ${response.data.token}` },
        });
    
        const tokenData = response.data;
        localStorage.setItem(`userProfileNameForIds`, getBelongName);
        setToken(tokenData.token);
        setIsLoading(true);

            
      const intervalId = setInterval(() => getSingleUser(newEntityData.userName), 8000);
      setIntervallId(intervalId);

      } catch (error) {
        handleError('Error during registration:', error);
      }

    };


    const getSingleUser = async (userName) => {
      try {
        const response2 = await api.get(`/api/v1/auth/user/${userName}`);
        const data = response2.data;
        setIsConfirmed(data.isConfirmed);
      } catch (error) {
        handleError('Error fetching user data:', error);
      }
    };
    
    const resendConfirmEmail = async () => {
      const newEmailData = {
        jwtToken: getToken,
        profileName: getBelongName,
      };
    
      try {
        const response = await api.post(
          `/api/v1/auth/resendConfirmEmail/${getUserName}-${getBelongName}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
    
        // Handle the response as needed
      } catch (error) {
        handleError('Error resending confirmation email:', error);
      }
    };


      useEffect(() => {
          setIsLoading(false);
          setIsConfirmed(false);
          setBelongName(localStorage.getItem('profileName'));
      }, []);


    useEffect(() => {
      
      if (getIsConfirmed === true) {
        console.log('getIsconfirmed:', getIsConfirmed);
        clearInterval(getIntervallId);  
      }

    }, [getIsConfirmed]);  
              

    const handleError = (message, error) => {
      console.error(message, error);
      setError('An error occurred. Please try again.');
      };


  return (
    <div className="AddPublisherEmployee ">
      <div className="row w-full">
        <div className="row row-col-12 text-center m-4">
                {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
        </div>

      <div className="container mb-40">
          
        <div className="row row-col-4 ">
  
        {getIsConfirmed && (
            <div className="py-5 h-100 text-center">
              <div className="row row-col-3 text-center h-100">
                <div className="col-sm-2 text-center"></div>
                <div className="col-sm-8 text-center colConfirmEmail">
                <div className="col-sm-12 items-center justify-content-center">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8"><img className="scale-50" src={logo} alt="logo" /></div>
                            <div className="col-sm-2"></div>
                      </div>
                  <h3 className="mt-1 mb-5 pb-1">Du hast dich erfolgreich verifiziert!</h3>
                  <p>Bitte Logge dich aus und wechsle zur Login-Seite um Dich in deinem Mitarbeiter-Account einzuloggen. </p>
                  <div className="text-center pt-1 mb-5 pb-1">
                    <Link to="/">
                      <button className="buttonTextColor text-2xl p-2 px-32 mx-4 btnChange mb-3" type="button" >Login - Seite</button>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-2 text-center"></div>
              </div>
            </div>
          )}
  
          {isLoading && !getIsConfirmed && (
            <div className="py-5 h-100 text-center">
              <div className="row row-col-3 text-center h-100">
                <div className="col-sm-2 text-center"></div>
                <div className="col-sm-8 text-center colConfirmEmail">
                <div className="col-sm-12 items-center justify-content-center">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8"><img className="scale-50 -mb-20" src={logo} alt="logo" /></div>
                            <div className="col-sm-2"></div>
                      </div>
                  <h3 className="mt-1 mb-5 pb-1">Warte auf Verifizierung deiner Email...</h3>
                  <div className="col-sm-12 items-center justify-content-center">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-8">
                          <p>Bitte sieh in deine Email, wir haben dir einen Verifizierungs-Link gesendet!</p>
                          <p>Möglicherweise musst du diese Seite Aktualisieren!</p>
                      </div>
                      <div className="col-sm-2"></div>
                  </div>
                  <button className="buttonTextColor text-2xl p-2 px-32 mx-4 btnChange mb-3 mt-5" type="button" onClick={resendConfirmEmail}>Email erneut senden</button>
                </div>
                <div className="col-sm-2 text-center"></div>
              </div>
            </div>
          )}
  
          {!getIsConfirmed && !isLoading && (
            <div className="py-5 min-h-screen flex justify-center items-center">
              <div className="row row-col-3 text-center">
                <div className="col-sm-2"></div>
                <div className="col-sm-8 colConfirmEmail text-center">
                <div className="col-sm-12 items-center justify-content-center">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8"><img className="" src={logo} alt="logo" /></div>
                            <div className="col-sm-2"></div>
                      </div>

                  <form onSubmit={register} className="">
                    <p>Bitte registriere Dich, um die Erstellung deines Mitarbeiter-Profils zu vollenden</p>
                    <div className="form-outline mb-4">
                      <input type="email" id="form2Example11" className="form-control" placeholder="Email-Adresse" name="userName" />
                      <label className="form-label" htmlFor="form2Example11">Email</label>
                    </div>
                    <div className="form-outline mb-4">
                      <input type="password" id="form2Example22" className="form-control" name="password" placeholder="Passwort" />
                      <label className="form-label" htmlFor="form2Example22">Passwort</label>
                    </div>
                    <div className="text-center pt-1 mb-5 pb-1">
                    <button className="buttonTextColor text-2xl p-2 px-32 mx-4 btnChange mb-3"
                      type="submit">Registrieren
                    </button>
                    </div>
                  </form>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </div>

          )}
          </div> 

      
    </div>   
  </div> 
</div>           
    
  );
}

export default AddPublisherEmployee; 
export { default as AddPublisherEmployee } from './AddPublisherEmployee';