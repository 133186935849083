import './CompFourAppFunc.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import compFourAppFuncPicMyProfile from '../../images/landingPage_Func_MyProfile4.png';
import compFourAppFuncPicDashboard from '../../images/landingPage_Func_Dashboard4.png';
import compFourAppFuncPicChat from '../../images/landingPage_Func_Chat4.png';
import compFourAppFuncPicOthProfile from '../../images/landingPage_Func_OthProfile5.png';
import compFourAppFuncPicProfile from '../../images/landingPage_Func_Profile5.png';


function CompFourAppFunc() { 


  const [ cardActiv, setCardActiv] = useState('Profile');

  const renderImage = () => {
    switch (cardActiv) {
      case 'Profile':
        return <img className="m-5 lg:mt-80 w-full h-full rounded-xl" src={compFourAppFuncPicProfile} alt="Profile" style={{maxHeight: '70%'}} />;
      case 'Mein Profil':
        return <img className="m-5 lg:mt-80 w-full h-full rounded-xl" src={compFourAppFuncPicMyProfile} alt="Mein Profil" style={{maxHeight: '70%'}} />;
      case 'Profilansichten':
        return <img className="m-5 lg:mt-80 w-full h-full rounded-xl" src={compFourAppFuncPicOthProfile} alt="Profilansichten" style={{maxHeight: '70%'}} />;
      case 'Dashboard':
        return <img className="m-5 lg:mt-80 w-full h-full rounded-xl" src={compFourAppFuncPicDashboard} alt="Dashboard" style={{maxHeight: '70%'}} />;
      case 'Chat':
        return <img className="m-5 lg:mt-80 w-full h-full rounded-xl" src={compFourAppFuncPicChat} alt="Chat"style={{maxHeight: '70%'}}  />;
      default:
        return null;
    }
  };


  useEffect(() => {
	setCardActiv('Profile');
  }, []);



  return (
    <div className="CompFourAppFunc w-full text-center max-sm:mb:80 max-sm:pb-80  mt-10 sm:mb-80">
      <div className="w-full py-10">
        <div className="mx-5 lg:ml-40 lg:mr-10">
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
            <div className="lg:col-span-2 pt-80 pb-80">
              <div className="grid grid-cols-1 gap-8 max-sm:pb-80">
              <div className=" pl-5 pr-12 px-4">
                  <div className="flex flex-col lg:flex-row w-full items-center justify-center max-sm:mb:90">
                    <div className="col-sm-1 px-40"></div>
                      <div className="col-sm-10  text-center px-40">
                        <h2 className="text-4xl lg:text-5xl font-bold mb-4 text-center">
                          Die Plattform
                          </h2>
                      </div>
                    </div>
                </div>
                {['Profile', 'Mein Profil', 'Profilansichten', 'Dashboard', 'Chat'].map((item) => (
                  <div
                    key={item}
                    className={`mb-3 ${cardActiv === item ? 'landingCompFourColor text-white rounded-3xl' : 'black-glassmorphism-card'}`}
                    onMouseEnter={() => setCardActiv(item)}
                  >
                    <h3 className="mt-1 mb-5 pt-3 ">{item === 'Profile' ? 'Die Profile' : item}</h3>
                    <p className="max-lg:hidden lg:block p-4 text-3xl">
                      {item === 'Profile' &&
                        'Du hast die Möglichkeit, ein Profil als Autor, Lektor & Korrektor, Testleser, Verlag oder Coverdesigner zu erstellen. Sobald du mehrere Rollen nutzen möchtest, erstelle einfach die zusätzlichen Profile in deinem Benutzerkonto.'}
                      {item === 'Mein Profil' &&
                        'Hier findest du eine Übersicht über all deine Informationen und kannst Einstellungen vornehmen. Wenn du nicht schon beim Erstellen deines Profils alle Informationen angegeben hast, kannst du hier weitere hinzufügen oder ändern.'}
                      {item === 'Profilansichten' &&
                        'In dieser Ansicht besuchst du die Profile von Kollegen, Freunden oder zukünftigen Partnern und erhältst alle Informationen, die sie teilen. Du kannst ihnen auch Rechte vergeben oder bei Zusammenarbeit eine Bewertung hinterlassen.'}
                      {item === 'Dashboard' &&
                        'Im Dashboard, dem Herzstück unserer Plattform, siehst du alle existierenden Profile, sortiert nach der Wertung der Bewertungen. Zusätzlich kannst du nach allen wichtigen Merkmalen filtern, zum Beispiel, ob ein Autor einen Lektor oder Testleser sucht, oder nach der Anzahl der Follower. Dies ist dein wichtigstes Tool.'}
                      {item === 'Chat' &&
                        'Natürlich kannst du auch, nachdem du im Dashboard einen interessanten Kontakt gefunden hast, direkt ein Gespräch in unserem Chat starten und neue Verbindungen knüpfen.'}
                    </p>
                    {cardActiv === item && (
                      <p className="lg:hidden p-4">
                        {item === 'Profile' &&
                          'Du hast die Möglichkeit ein Profil als Autor, Lektor & Korrektor, Testleser, Verlag oder Coverdesigner zu erstellen. Sobald Du mehrere Rollen nutzen möchtest erstelle einfach die zusätzlichen Profile in deinem User.'}
                        {item === 'Mein Profil' &&
                          'Hier findest du eine Übersicht über all deine Informationen und kannst Einstellungen vornehmen. Wenn du nicht schon beim Erstellen deines Profils alle Informationen angegeben hast, kannst du hier weitere hinzufügen oder ändern.'}
                        {item === 'Profilansichten' &&
                          'In dieser Ansicht besuchst du die Profile von Kollegen, Freunden oder zukünftigen Partnern und erhältst alle Informationen, die sie teilen. Du kannst ihnen auch Rechte vergeben oder bei Zusammenarbeit eine Bewertung hinterlassen.'}
                        {item === 'Dashboard' &&
                          'Im Dashboard, dem Herzstück unserer Plattform, siehst du alle existierenden Profile, sortiert nach der Wertung der Bewertungen. Zusätzlich kannst du nach allen wichtigen Merkmalen filtern, zum Beispiel, ob ein Autor einen Lektor oder Testleser sucht, oder nach der Anzahl der Follower. Dies ist dein wichtigstes Tool.'}
                        {item === 'Chat' &&
                          'Natürlich kannst du auch, nachdem du im Dashboard einen interessanten Kontakt gefunden hast, direkt ein Gespräch in unserem Chat starten und neue Verbindungen knüpfen.'}
                      </p>
                    )}
                    {cardActiv === item && <div className="lg:hidden pr-10" >{renderImage()}</div>}
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:col-span-3 lg:block max-lg:hidden lg:sticky lg:top-0" style={{maxHeight: 1000, maxWidth:1600}}>{renderImage()}</div>
          </div>
        </div>
      </div>
    </div>
  
  );
}

export default CompFourAppFunc; 
export { default as CompFourAppFunc } from './CompFourAppFunc';