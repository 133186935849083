
import './ShareProfileAuthorView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState } from 'react';

import { FaPencilAlt } from "react-icons/fa";
import { FaGlasses } from "react-icons/fa6";
import { AiFillPicture } from "react-icons/ai";

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import he from 'he';
import DOMPurify from 'dompurify';

function IconWithTooltip({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className="col col-sm-1 FormStyling text-left"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          style: { fontSize: '3rem' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }

  function DisplayHtml({ htmlContent }) {   
    //const escapedHtml = he.escape(htmlContent);  only html visible and not in formated way visible!
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    );
}



const ShareProfileAuthorView = ({ getUserProfileType, getCurrentUserProfileName, authorState, setAuthorState, baseUrl }) => {


///////////////////////////////////////////////////////////////////////
    ////    Code for show Profile ...    //////////////////
    /////////////////////////////////////////////////////////////////////


    const [getSingleEntityData,setSingleEntityData] = useState([]);                                                                   
    const [getSingleBookprojectData,setSingleBookprojectData] = useState([]);                    
    const [getSingleEntityRating,setSingleEntityRating] = useState([]); 
    
    const [ratingScoreAverage, setRatingScoreAverage] = useState();
    
    const [imageSrc, setImageSrc] = useState([]); 
    const [ratingText, setRatingText] = useState(''); 

    const [expandedTexts, setExpandedTexts] = useState({});
    const [expandedColumn, setExpandedColumn] = useState('Author');
    const [expandedColumn2, setExpandedColumn2] = useState('Testreader');

    const [getTrueGenreData, setTrueGenreData] = useState([]);

  const [error, setError] = useState(null);


    const toggleCollapse = (column) => {   
        setExpandedColumn(column);    
    };

    const toggleCollapse2 = (column) => {
        setExpandedColumn2(column);
    };
    
    const toggleTextExpansion = (createdByProfileName) => {
      setExpandedTexts((prevExpandedTexts) => ({
          ...prevExpandedTexts,
          [createdByProfileName]: !prevExpandedTexts[createdByProfileName],
      }));
  };

    const getSingleEntity = async () => {
        try {
            const addNewEntityData = {
              entityName: getCurrentUserProfileName,
            };

            const response = await api.get(`/api/authors/share/${addNewEntityData.entityName}`); 
    
            setSingleEntityData(response.data);
            const singleEntity = response.data;
            setSingleBookprojectData(singleEntity.bookProjectsIds);
            showSingleRatings(singleEntity.authorName);

	    setAuthorState(false);

      const filteredGenres = Object.keys(singleEntity.genresIds[0])
      .filter(key => key !== '_id' && singleEntity.genresIds[0][key] === true);
      setTrueGenreData(filteredGenres);
		
        } catch (error) {
            handleError('Error fetching single Entity data:', error);
        }
    };


    
    const showSingleRatings = async (entityName) => {
        try {
            const response = await api.get(`/api/ratings/createdFor/${entityName}`);
    
            setSingleEntityRating(response.data);
    
            const sumOfRatingScores = response.data.reduce((sum, item) => sum + item.ratingScore, 0);
            const averageRatingScore = sumOfRatingScores / response.data.length;
            const roundedAverageRatingScore = averageRatingScore.toFixed(1);
    
            if (response.data.length > 0) {
                setRatingScoreAverage(parseFloat(roundedAverageRatingScore));
            } else {
                setRatingScoreAverage('');
            }
        } catch (error) {
            handleError('Error showing single ratings:', error);
        }
    };
   
    

    const handleRatingTextChange = (event) => {
        setRatingText(event.target.value);
    };

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (getUserProfileType === 'Author') {
                    const entityData = getSingleEntity();
                    setSingleEntityData(entityData);
                    console.log('Authors:', entityData);
                }
            } catch (error) {
                handleError('Error adding rating:', error);
            }
        };
 
        fetchData();
        setImageSrc(`${baseUrl}/api/files/image/`);
        setExpandedColumn('Author');
        setExpandedColumn2('Testreader');
    }, [getUserProfileType]);
    


      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };


        return (
            <div className="OthersProfileAuthorView">

              {/*  {error && <div style={{ color: 'red' }}>{error}</div>}   */}
          
              <div className='container'>
                {getUserProfileType === "Author" && (
                  <div className=" ">
                    <div className="col">
                      {getSingleEntityData && (
                        <div className="row">
                          <div className='max-md:flex'>
                            {getSingleEntityData.searchForLector && (<IconWithTooltip icon={<FaPencilAlt />} tooltipText="Suche nach Lektor | Korrektor aktiviert" />)}
                            {getSingleEntityData.searchForTestReader && (<IconWithTooltip icon={<FaGlasses />} tooltipText="Suche nach Testleser aktiviert" />)}
                            {getSingleEntityData.searchForCoverDesign && (<IconWithTooltip icon={<AiFillPicture className='scale-110'/>} tooltipText="Suche nach Cover-Design aktiviert" />)}
                          </div>
                        </div>
                      )}
          
                <div className="row profileDataCard2  paddingOne">
                    <div className="row titleRowBackgroundcolor white-glassmorphism-card ">
                          <div className="col col-sm-8 sm:col-sm-12 max-sm:text-center  md:col-sm-6">
                            <h2 className="FormStyling md:text-left  titleTextColor">{getCurrentUserProfileName}</h2>
                            <h4 className="FormStyling md:text-left pr-10 pt-3 titleTextColor"> | {getSingleEntityData.follower} Follower</h4>
                          </div>
                        </div>
                        <hr className='horizontalLine' />
			        <div className="row mainRowBackgroundcolor sm:flex white-glassmorphism-card pt-3 pb-3">
                    	<div className='col col-sm-8'>
                         <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                      	   <div className='max-md:mb-20  max-lg:w-full' style={{minWidth: 200}}>
                              <h3 className="FormStyling">Name</h3>
                              <h4 className="FormStyling">{getSingleEntityData.authorName}</h4>
                              <br></br>
                              <h3 className="FormStyling">Alter</h3>
                              <h4 className="FormStyling">{getSingleEntityData.age}</h4>
                            </div>
                          </div>
          
                         <div className="col col-sm-6 flex w-full max-sm:text-center sm:text-left">
                           <div className='max-sm:mb-10 max-lg:w-full' style={{minWidth: 200}}>
                              <h3 className="FormStyling">Website</h3>
                              <h4 className="FormStyling">{getSingleEntityData.website}</h4>
                              <br></br>
                              <h3 className="FormStyling">Genres</h3>
                              <div className='flex'>
                              { getTrueGenreData && getTrueGenreData.map((key, index) => (
                                  <h4 key={key} className="FormStyling capitalize">{key}{getTrueGenreData.length > (index+1)?", ":""}</h4>
                                ))}
                          </div>
                          </div>
                        </div>
                       </div>
                       <div className='col col-sm-12'>
                          <div className="col col-sm-6 max-lg:w-full text-center md:text-left max-md:mb-20" >
                            <div className="row  textAreaOne">
                              <h3 className="FormStyling">Social Media Accounts</h3>
                              {getSingleEntityData.socialMediaAccounts && getSingleEntityData.socialMediaAccounts.map((account, index) => (
                                <h4 key={index} className="FormStyling">{account}</h4>
                              ))}
                          </div>
                        </div>
                        <div className='col-sm-6 flex text-center justify-center items-center '>
                        <div className="profileImg  my-3  ">
                          <div className="profileImg2" style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }}>                          
                              <img className="img-fluid profileImg3" src={`${imageSrc}${getSingleEntityData.profileImg}`} alt="Dein Profilbild"
                                  style={{ maxWidth: '240px', maxHeight: '240px', minWidth: '240px', minHeight: '240px' }} />
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
          
                {(getSingleEntityData.experience && getSingleEntityData.experience.length > 0) && (
                  <div className="row profileDataCard2  paddingOne">
                    <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                      <h2 className="FormStyling text-left titleTextColor">Erfahrung</h2>
                    </div>
                    <hr className='horizontalLine' />
                    <div className="row mainRowBackgroundcolor white-glassmorphism-card">
                          <div className="col col-sm-12 text-left ml-5 mainRowBackgroundcolor py-5">
                              <DisplayHtml htmlContent={getSingleEntityData.experience} />
                          </div>
                      </div>
                  </div>
                )}
              
            

              {getSingleBookprojectData && (getSingleBookprojectData.length > 0) && (
                        <div className="row profileDataCard2  paddingOne">
                          <div className="row titleRowBackgroundcolor paddingTwo white-glassmorphism-card ">
                            <h2 className="FormStyling text-left titleTextColor">Buchprojekte</h2>
                          </div>
                          <div className="row titleRowBackgroundcolor2 white-glassmorphism-card ">
                            <div className="col col-sm-3 text-left"><h3 className="topRowStyling max-md:hidden">Titel</h3></div>
                            <div className="col col-sm-6 text-left"><h3 className="topRowStyling  max-md:hidden">Beschreibung</h3></div>
                            <div className="col col-sm-3 text-left"><h3 className="topRowStyling  max-md:hidden">Seitenanzahl</h3></div>
                          </div>
                          <hr className='horizontalLine' />

            {getSingleEntityData.bookProjectsIds && getSingleEntityData.bookProjectsIds.map((bookProjects, index) => (
                <div key={bookProjects.title}>
                		<div className='max-md:divide-y-3 '>
                		{index !== 0 && (<hr className='horizontalLine2 max-md:hidden' />)}
                 		 <div className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card ">
                 		   <div className="col col-sm-3 text-left max-md:flex"><h3 className="FormStyling md:hidden">Titel: </h3>
					<h4 className="FormStyling max-md:pt-2 max-md:pl-2">
                                      		{bookProjects.title}		
					</h4>
				   </div>
                  		  <div className="col col-sm-6 text-left"><h3 className="FormStyling md:hidden">Beschreibung: </h3><h4 className="FormStyling max-md:pt-2 max-md:pl-2"><DisplayHtml htmlContent={bookProjects.jacketText} /></h4></div>
                  		  <div className="col col-sm-3 text-left max-md:flex"><h3 className="FormStyling md:hidden">Seitenanzahl: </h3><h4 className="FormStyling max-md:pt-2 max-md:pl-2">{bookProjects.pages}</h4></div>
                  		  </div>
               		    </div>
             		 </div>
           		 ))}
            		<div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
          	    </div>
       		 )}
                            
           {getSingleEntityData.favoriteIds && (getSingleEntityData.favoriteIds.length > 0) && (
             <div className="row profileDataCard2  paddingOne white-glassmorphism-card">
            	<div className="row row-col-3 titleRowBackgroundcolor  ">
              <div className="col col-sm-12 text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Favoriten</h2></div>
            </div>
  
             <div className="row flex w-full titleRowBackgroundcolor2 ">
              <div className='col col-sm-12 xl:flex max-xl:pl-6 w-full'>
                <div className={`xl:col-sm-3 flex max-xl:text-left`}  style={{ width: expandedColumn === 'Author' ? '33%' : '33%' }} onClick={() => toggleCollapse('Author')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Author'? 'favoriteColor ':''}`}>Autoren{ expandedColumn === 'Author' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-3 flex max-xl:text-left'  style={{ width: expandedColumn === 'Book' ? '33%' : '33%' }} onClick={() => toggleCollapse('Book')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Book'? 'favoriteColor ':''}`}>Bücher{ expandedColumn === 'Book' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-3 flex max-xl:text-left'  style={{ width: expandedColumn === 'Editor' ? '33%' : '33%' }} onClick={() => toggleCollapse('Editor')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn === 'Editor'? 'favoriteColor ':''}`}>Lektoren{ expandedColumn === 'Editor' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
              </div>
              <div className='col col-sm-12 xl:flex w-full'>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'Testreader' ? '33%' : '33%' }} onClick={() => toggleCollapse2('Testreader')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'Testreader'? 'favoriteColor ':''}`}>Testleser{ expandedColumn2 === 'Testreader' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'CoverDesigner' ? '48%' : '48%' }} onClick={() => toggleCollapse2('CoverDesigner')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'CoverDesigner'? 'favoriteColor ':''}`}>CoverDesigner{ expandedColumn2 === 'CoverDesigner' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
                <div className='xl:col-sm-4 flex max-xl:text-left'  style={{ width: expandedColumn === 'Publisher' ? '20%' : '20%' }} onClick={() => toggleCollapse2('Publisher')}><h3 className={`flex topRowStyling max-sm:text-3xl ${expandedColumn2 === 'Publisher'? 'favoriteColor ':''}`}>Verlage{ expandedColumn2 === 'Publisher' ? (<CgChevronDown />) : (<CgChevronRight />) }</h3></div>
              </div> 
            </div> 
            <hr className='horizontalLine' />
            <div className="row flex mainRowBackgroundcolor2 paddingThree">
              <div className='col  sm:flex w-full'>
                {["Author", "Book", "Editor","Testreader", "CoverDesigner","Publisher"].map((categoryType) => (

                 <><><> {expandedColumn === categoryType && (expandedColumn === 'Author' || expandedColumn === 'Book' || expandedColumn === 'Editor') && (

                    <div className='col ml-5 w-1/2'>
                      <div key={categoryType} className='text-left'>
                        {getSingleEntityData.favoriteIds && getSingleEntityData.favoriteIds.map((favorite, index) => (
                          <div key={favorite.userProfileName}>
                            {favorite.userProfileType === categoryType && (
                              <><>{expandedColumn === categoryType && index <= 2 && (<h4 className='sm:hidden text-left ml-5 font-bold'>{expandedColumn === 'Author' ? 'Autoren:' : expandedColumn === 'Editor' ? 'Lektor:' : 'Buecher:'}</h4>)}
                              </><div className='flex '>
                                <div className='' style={{ minWidth: '200px', maxWidth: '250px' }}>
                                  <h4 className="FormStyling">
                                      {favorite.userProfileName}
                                  </h4>
                                </div>
                              </div></>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  </><> {expandedColumn2 === categoryType && (expandedColumn2 === 'Testreader' || expandedColumn2 === 'CoverDesigner' || expandedColumn2 === 'Publisher') && (
                    <div className='col ml-5 sm:-ml-5 w-1/2'>
                      <div key={categoryType} className='text-left '>
                        {getSingleEntityData.favoriteIds && getSingleEntityData.favoriteIds.map((favorite, index) => (
                          <div key={favorite.userProfileName}>
                            {favorite.userProfileType === categoryType && (
                                <><> {expandedColumn2 === categoryType && index <= (index + 1) && (<h4 className='sm:hidden text-left ml-5 font-bold'>{expandedColumn2 === 'Testreader' ? 'Testleser:' : expandedColumn2 === 'CoverDesigner' ? 'Coverdesigner:' : 'Verlage:'}</h4>)}</><div className='flex '>
                                <div className='' style={{ minWidth: '200px', maxWidth: '250px' }}>
                                  <h4 className="FormStyling">
                                      {favorite.userProfileName}
                                  </h4>
                                </div>
                              </div></>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                    </></></>
     
                ))}
    
             </div>
            </div>
            <div className="row mainRowBackgroundcolor paddingOne"><br></br></div>
          </div>
        )}

        {getSingleEntityRating && (getSingleEntityRating.length > 0) && (
          <div className="row profileDataCard2  paddingOne mb-20">
            <div className="row row-col-3 max-md:flex  titleRowBackgroundcolor white-glassmorphism-card">
              <div className="col md:col-sm-9 col-sm-6 w-full text-left paddingTwo"><h2 className="FormStyling text-left titleTextColor">Ratings</h2></div>
              <div className="col md:col-sm-1  col-sm-4 w-full text-right md:text-left paddingZero">
                <h4 className="FormStyling text-right">Gesamt: </h4>
              </div>
              <div className="col md:col-sm-2 col-sm-1 w-full text-left paddingZero">
                <h2 className="FormStyling text-left paddingZero">{ratingScoreAverage}</h2>
              </div>
            </div>
            <div className="row titleRowBackgroundcolor2 max-md:flex white-glassmorphism-card">
              <div className="col col-sm-1 max-md:hidden text-left"><h3 className="topRowStyling">Wert</h3></div>
              <div className="col col-sm-3 max-md:hidden text-left"><h3 className="topRowStyling">Von</h3></div>
              <div className="col col-sm-8 max-md:hidden text-left"><h3 className="topRowStyling">Kommentar</h3></div>
            </div>
            <hr className='horizontalLine' />

      
            {getSingleEntityRating.map((rating, index) => (
              <div key={rating.createdByProfileName} className="row mainRowBackgroundcolor2 paddingThree white-glassmorphism-card">
                <div className="col col-sm-1 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Wert:</h3>
                  <h4 className="FormStyling max-md:pl-3 ">{rating.ratingScore}</h4>
                </div>
                <div className="col col-sm-3 max-md:flex text-left">
                  <h3 className="topRowStyling md:hidden max-md:pt-2 titleTextColor">Von:</h3>
                  <h4 className="FormStyling max-md:pl-3">{rating.createdByProfileName}</h4>
                </div>
                <div className="col col-sm-8 text-left">
                  <div
                    style={{
                      maxWidth: '730px', // Set a fixed height as an example, adjust as needed
                      overflow: 'hidden',
                      cursor: 'pointer'
                    }}
                  >
                    <h3 className="topRowStyling md:hidden titleTextColor">Kommentar:</h3>
                    <h4
                      className="FormStyling"
                      onClick={() => toggleTextExpansion(rating.createdByProfileName)}
                    >
                      {expandedTexts[rating.createdByProfileName]
                        ? rating.ratingText // Show full text if expanded
                        : `${rating.ratingText.substring(0, 80)}...` // Show truncated text
                      }
                    </h4>
                  </div>
                  <hr style={{ borderColor: 'lightgray' }} className={`horizontalLine mt-5 mb-5 ${(index === 1 ||  getSingleEntityRating.length === index +1) ?'hidden':'sm:hidden'}`}/>
                           
                </div>
              </div>
            ))}
            <div className="row mainRowBackgroundcolor paddingOne white-glassmorphism-card"><br></br></div>
          </div>
        )}
        
        </div>
        </div> 
         
      )} 

        </div>        
    </div>  
    
    
  );
}

export default ShareProfileAuthorView; 
export { default as ShareProfileAuthorView } from './ShareProfileAuthorView';