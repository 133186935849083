import './home.css';
import { Link } from "react-router-dom";

//Components
import { WaitingListMemberQuizPlusUiComp } from '../components/landingPage/WaitingListMemberQuizPlusUiComp';
import { CompTwoBenefits  } from '../components/landingPage/CompTwoBenefits';
import { CompThreePic } from '../components/landingPage/CompThreePic';
import { CompFourAppFunc } from '../components/landingPage/CompFourAppFunc';
import { CompFiveFAQ } from '../components/landingPage/CompFiveFAQ';
import { IntroComp } from '../components/landingPage/IntroComp';
import { CompFreeSpace } from '../components/landingPage/CompFreeSpace';
import { CompMoreInfo } from '../components/landingPage/CompMoreInfo';

import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';




const Home = ({baseUrl}) => {
//


  return (

    <div className="Home w-full skin-glassmorphism-background" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
       <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div> 
          <div className='gradient-bg-bluumer'>  
                <div className="component-wrapper w-full">
                  <IntroComp />
                </div>
                <div className="component-wrapper w-full sm:mb-28 lg:mb-10 lg:mt-80 ">
                  <CompTwoBenefits />
                </div>
                <div className="component-wrapper w-full">
                  <CompMoreInfo />
                </div>
                <div className="component-wrapper w-full max-sm:-pb-80 max-sm:-mb-40 sm:mt-20 sm:pb-20 sm:mb-20">
                  <CompFreeSpace />
                </div>
                
                <div className="component-wrapper w-full max-sm:mb-80  mb-80">
                  <CompFourAppFunc />
                </div>
                <div className="component-wrapper w-full  max-sm:hidden mt-80 pb-80 mb-80">
                  <CompFreeSpace />
                </div>
                <div className="component-wrapper w-full max-sm:mb-80 max-sm:pb-80 sm:mt-80 mt-80 ">
                  <CompThreePic />
                </div>
                <div className="component-wrapper w-full max-sm:hidden max-sm:mb-0  mb-0">
                  <CompFreeSpace />
                </div>
                <div className="component-wrapper w-full max-sm:mt-80 max-sm:pt-80 sm:mb-28 mb-80 pb-80">
                  <CompFiveFAQ />
                </div>
                <div className="footer-wrapper w-full">
                  <Footer />
                </div>
          </div>
    </div>

  );
  
}

export default Home; 