import './CompMoreInfo.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 

import Pic1 from '../../images/ranking4.png';
import Pic2 from '../../images/sharing2.jpg';

import { BsArrowRightSquareFill } from "react-icons/bs";
import { BsArrowDownSquareFill } from "react-icons/bs";

function CompMoreInfo() { 


  return (

<div className="CompMoreInfo text-center max-sm:mb:10 max-sm:pb-10 mt-80 pt-40 max-sm:pt-0">
    
            {/* Feature Carousel */}
            <div>
            <div className="features py-10 mb-80">
                <h1 className='text-center mb-20'>Highlights von Bluumer</h1>
                <div className="container mx-auto ">
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} className='bg-black'>
                        <div className='relative '>
                            <img src={Pic1} className='' alt="Für Autoren" />
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                             
                                <h3 className="text-white  max-sm:hidden text-6xl p-4 mx-10">Bewertungen und Ranking<br></br><br></br>
                                <h2> Bewertungen eines jeden Profiles und Buches nach Zusammenarbeit, ermöglichen durch gute 
                                  Qualität hervorzustechen und helfen Anderen schon zuvor im Dashboard interessante Profile und Bücher an ihrem Rang zu erkennen.
                                </h2>
                                </h3>

                                <h4 className="text-white sm:hidden text-3xl p-4 mx-10">Bewertungen und Ranking<br></br>
                                <h5>  Bewertungen eines jeden Profiles und Buches nach Zusammenarbeit, ermöglichen durch gute 
                                  Qualität hervorzustechen und helfen Anderen schon zuvor im Dashboard interessante Profile und Bücher an ihrem Rang zu erkennen.
                                </h5>
                                </h4>
                            </div>
                        </div>
                        <div className='relative'>
                            <img src={Pic2} className='' alt="Für Lektoren" />
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <h3 className="text-white max-sm:hidden text-6xl p-4 mx-10">Ein Link zum Teilen<br></br><br></br>
                                <h2> Damit Du Deine Fortschritte, Angebote und dein Netzwerk auch Anderen außerhalb von Bluumer zeigen
                                  kannst, teile einfach mit Ihnen die Leseansicht zu Deinem Profil und Buch.
                                </h2>
                                </h3>

                                <h4 className="text-white sm:hidden text-3xl p-4 mx-10">Ein Link zum Teilen<br></br><br></br>
                                <h5> Damit Du Deine Fortschritte, Angebote und dein Netzwerk auch Anderen außerhalb von Bluumer zeigen
                                  kannst, teile einfach mit Ihnen die Leseansicht zu Deinem Profil und Buch.
                                </h5>
                                </h4>
                                </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>

            {/* How It Works Section */}
            <div className="how-it-works py-10">
                <div className="container mx-auto text-center">
                    <h1 className="">So schnell & einfach funktioniert's</h1>
                    <div className="flex flex-wrap justify-center mt-8">
                        <div className="w-full sm:w-1/4 p-4">
                            <h3 className="text-3xl font-semibold">Profil anlegen</h3>
                            <p>Erzähle uns, wer Du bist und was Du suchst und erstelle ein kostenloses Konto</p>
                        </div>
                        <BsArrowRightSquareFill className='max-sm:hidden mt-20 scale-150 mx-10'></BsArrowRightSquareFill>
                        <BsArrowDownSquareFill className='sm:hidden my-10 scale-150'></BsArrowDownSquareFill>
             
                        <div className="w-full sm:w-1/4 p-4">
                            <h3 className="text-3xl font-semibold">Netzwerken</h3>
                            <p>Finde im Dashboard andere Buch-Professionals und verbinde Dich mit Ihnen </p>
                        </div>
                        <BsArrowRightSquareFill className='max-sm:hidden mt-20 scale-150 mx-10'></BsArrowRightSquareFill>
                        <BsArrowDownSquareFill className='md:hidden my-10 scale-150'></BsArrowDownSquareFill>
                        
                        <div className="w-full sm:w-1/4 p-4">
                            <h3 className="text-3xl font-semibold">Projekte starten</h3>
                            <p>Beginne Deine Zusammenarbeit</p>
                        </div>
                    </div>
                </div>
            </div>

      </div>

  
  );
}

export default CompMoreInfo; 
export { default as CompMoreInfo } from './CompMoreInfo';