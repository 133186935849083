import api from '../../api/axiosConfig';

import './IntroCompLogin.css';

import { useEffect, useState, Link } from 'react';
import { useNavigate } from "react-router-dom";

import Partnership_Pic from '../../images/Traumwelten Lektorat6.png';

function IntroCompLogin () {

    const [getThereWasAnError,setThereWasAnError] = useState(false);
    const [getIsUnConfirmed, setIsUnConfirmed  ] = useState(false);
    const [getUserNotExists, setUserNotExists  ] = useState(false);
    const [getShowResetPassword, setShowResetPassword  ] = useState(false);
    const [getCurrentProfileImg, setCurrentProfileImg  ] = useState('');
    const [getProfileType, setProfileType  ] = useState('');
    
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const loginFunc = async (e) => {
        e.preventDefault();

        const { target }  = e;

        const newAuthData = {
          userName: target.userName.value,
          password: target.password.value
        }

        localStorage.setItem(`userEmail`, target.userName.value);

        try{

            const response2 = await api.get(`/api/v1/auth/user/${newAuthData.userName}` );
            const isConfirmed = response2.data.isConfirmed

            if(isConfirmed){
                if(response2.data != null){
                    const response = await api.post(`/api/v1/auth/login`, newAuthData);
                    //console.log('Authorization:', response.data);
                    localStorage.setItem(`logintoken`, response.data.token );
                    
                                          
                    const userProfileType = response2.data.userProfilesIds[0].userProfileType;
                    const userProfileName = response2.data.userProfilesIds[0].userProfileName;
                    const userRole = response2.data.userRole;
                    const userProfileIdTimestamp = response2.data.userProfilesIds[0].id.timestamp;

                    localStorage.setItem(`currentProfileType`, userProfileType);
                    localStorage.setItem(`profileName`, userProfileName);
                    localStorage.setItem(`userRole`, userRole);
                    localStorage.setItem(`userProfileIdTimestamp`, userProfileIdTimestamp);
                    //console.log(localStorage.getItem('userRole'));
                    //console.log('userProfileIdTimestamp ', localStorage.getItem(`userProfileIdTimestamp`));
            

                    // Mapping of user profile types to corresponding profile types
                    const profileTypeMap = {
                      'Author': 'authors',
                      'Editor': 'editors',
                      'Testreader': 'testReaders',
                      'Publisher': 'publishers',
                      'CoverDesigner': 'coverDesigners'
                    };
                
                    // Determine the profile type
                    const profileType = profileTypeMap[userProfileType] || '';
                
                    // Function to fetch profile image and handle navigation
                    const fetchProfileImage = async () => {
                      try {
                        const response3 = await api.get(`/api/${profileType}/${response2.data.userProfilesIds[0].userProfileName}`, {
                          headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
                        });
                        
                        const profileImg = response3.data.profileImg;
                        setCurrentProfileImg(profileImg);
                        localStorage.setItem('currentProfileImg', profileImg);
                        //console.log('response3.data.profileImg:', profileImg);

                      } catch (error) {
                        console.error('Error fetching profile image:', error);
                      }
                    };

                                    
                    if (response.data) {
                      navigate('/dashboard');
                      localStorage.setItem('logoutTrue', 'false');
                    }
                
                    // Set a timeout to fetch the profile image
                    const delayTimeout = setTimeout(fetchProfileImage, 100);
                
                    // Clear the timeout on cleanup
                    return () => clearTimeout(delayTimeout);
                
                    

                }else{ setUserNotExists(true)
                }
            }else {setIsUnConfirmed(true)
            }
          } catch (error) {
            handleError('Error during login:', error);
          }
    };


    const resetPassword = async (e) => {
      e.preventDefault();
    
      const { target } = e;
    
      try {
        const response2 = await api.get(`/api/v1/auth/user/${target.userName.value}`);
        const isConfirmed = response2.data.isConfirmed;
    
        if (isConfirmed) {
          if (response2.data != null) {
            const response = await api.post(`/api/v1/auth/resetPasswordEmail/${target.userName.value}`);
            if (response.data !== true) {
              setThereWasAnError(true);
            }
          } else {
            setUserNotExists(true);
          }
        } else {
          setIsUnConfirmed(true);
        }
    
      } catch (error) {
        handleError('Error during password reset:', error);
      }
    };

    const changeToCreateProfileSite = () => {
      localStorage.setItem('logoutTrue', 'false');
      navigate('/createFirstProfile');
    }

    const changeResetPassword = () => {
      setShowResetPassword(!getShowResetPassword);
    }

   
    useEffect(() => {
      setUserNotExists(false);
      setIsUnConfirmed(false);
      setShowResetPassword(false);
      setThereWasAnError(false);
      setProfileType('Empty'); 
      }, []);

      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

        return (

          <div className="IntroComp">
          <div className="w-full ">
             <div className="row row-col-12 text-center m-4">
                   {/*}  {error && <div style={{ color: 'red' }}>{error}</div>}     */}
             </div>
     
           <div className=" "> 
             <div className=" ">
     
    
     
               <div className="justify-center landing-bg text-center items-center h-full ">
     
                 <div className="text-white">  
               <div className='row  -mt-20 mb-0 pt-40 justify-center items-center'>
                         <div className='col-sm-12 mt-0 mb-10 landingColor'>
                         <div className='row'>
                                 <div className="col col-sm-6">
                                 <h1 className="mb-10 -mt-20 sm:ml-10 pt-20 font-semibold sm:text-left max-sm:text-center text-black sm:text-8xl max-sm:text-7xl">Der Marktplatz für <br></br>Buch-Professionals</h1>
                                 </div>
                                 <div className="col col-sm-6"></div>
                             </div>          
                         </div>
                 </div>
                 <div className='row  mt-80 mb-0 pt-0 justify-center items-center'>
                         <div className='col-sm-12 mt-40 mb-10 landingColor'>
                         <div className='row'>
                                 <div className="col col-sm-5"></div>
                                 <div className="col col-sm-7">
                                     <h3 className="mb-10 text-center font-bold sm:text-white max-sm:text-black max-sm:pb-40" >
                                         Wir bringen Kreative, Dienstleister und Verleger der Buch-Industrie zusammen, einfach und schnell!
                                         <br></br>Sei dabei mit deinem Manuskript bis zur Veröffentlichung.
                                       </h3>
                                   </div>
                             </div>          
                         </div>
                 </div>
     
                 </div>
     

              <div className='col col-sm-12 w-full  compThreeBGColor'>
                <div className="col col-sm-6 w-full p-8 mb-0">
                 <div>
                  {!getShowResetPassword && (
                    <div className=''>
                      <h3 className="mt-10 mb-10 pb-1 gradient-textLanding  font-bold text-6xl">Hier kannst du dich Einloggen!</h3>
        
                      <form onSubmit={loginFunc} className="flex flex-col items-center justify-center">
                        <div className="form-outline mb-4 w-full" style={{ minWidth: '300px',maxWidth: '500px'}}>
                          <input
                            type="email"
                            id="form2Example11"
                            className="form-control"
                            placeholder="Email Adresse"
                            name="userName"
                          />
                          <label className="form-label text-white" htmlFor="form2Example11">
                            Email
                          </label>
                        </div>
        
                        <div className="form-outline mb-4 w-full" style={{ minWidth: '300px',maxWidth: '500px'}}>
                          <input
                            type="password"
                            id="form2Example22"
                            placeholder="Passwort"
                            className="form-control"
                            name="password"
                          />
                          <label className="form-label text-white" htmlFor="form2Example22">
                            Passwort
                          </label>
                        </div>
        
                        <div className="text-center pt-1 mb-5 pb-1 ">
                          <button className="text-4xl py-4 px-36 mx-4 btnLanding hover:text-black hover:bg-yellow-100" type="submit">
                            <b>Log In</b>
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                 </div>

                 <div className='col-sm-12 items-center justify-center'>
                    {!getShowResetPassword && (
                      <div>
                      <div className="col-sm-3"></div>
                      <div className="col-sm-12 text-center pt-1 mb-10 pb-1">
                      <button className="text-white p-3 mx-4 buttonTwo cursor-pointer" onClick={changeResetPassword}>
                        Passwort vergessen
                      </button>

                      </div>
                      </div>
                    )}
                  </div>
                  </div>
                  <div className="col col-sm-6 w-full max-sm:pb-48 mt-60 p-8 mb-0">
                      <button className=" text-4xl py-4 px-36 max-sm:px-20 mx-4  text-white profileCreationColor hover:text-black  " onClick={changeToCreateProfileSite}>
                            <b>Profil erstellen</b>
                              </button>  
                      </div>
                  </div>
                 <div className='col col-sm-12 w-full pt-28 gradient-bluumer '>
                   <h4 className="mb-8 text-center font-bold text-gray-600">UNSERE VERLÄSSLICHEN PARTNER AUS DEM BUCH-PROFESSIONAL BEREICH</h4>
                   <div className="flex justify-center items-center">
                     <img className='m-5' src={Partnership_Pic} alt="Partnership_Pic" style={{ maxHeight: '200px', minHeight: '50px' }} />
                   </div>
                 </div> 
               </div>
     
     
               </div> 
     
               <div className="row w-full ">

               {getShowResetPassword && (
              <><div className=''>
                    <div>
                           <div className='blurPage z-20 fixed inset-0 h-screen w-screen'></div>
                           <div className='loadingBox p-10 compThreeBGColor flex flex-col fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' 
                             style={{ minWidth: '400px' , minHeight:'300px'}}>
                             <div className="w-full text-black">
                             
                               <div className=' col-sm-12 justify-center items-center'>
                               <div className="row">
                                    <div className='col-sm-12 text-center max-sm:text-white'><h2 className="FormStyling text-center gradient-textLanding  ">Passwort Reset!</h2></div>
                                    <div className='col-sm-12 py-5'>
                                        <button className=" px-6 py-1 btnLanding hover:text-black hover:bg-yellow-100 rounded-md mr-2 " type="button" onClick={changeResetPassword}>
                                          zurück
                                        </button>
                                    </div>
                                </div>
                            <div>
                              <form onSubmit={resetPassword}>
                              <div className="row">
                                  <div className='col col-sm-12 pb-5'>
                                      <input type="email" id="form2Example11" className="form-control" placeholder="Email Adresse" name="userName" />
                                      <label className="form-label text-white" htmlFor="form2Example11">
                                        Benutzername / Email
                                      </label>
                                </div>
                                <p className='mt-5 text-white'>Check deine Emails, wir senden Dir einen Link!</p>
                                <div className="col col-sm-12 FormStyling text-center justify-center items-center mb-5">
                                      <button className=" px-8 py-4 btnLanding hover:text-black hover:bg-yellow-100 rounded-md mr-2" type="submit">Passwort zurücksetzen</button>
                                    </div>
                                </div> 
                              </form>
                              </div>
                          </div>   
                    </div>
                </div>
             </div>   
          </div>
            </> 
            )}    
     
     
           </div>
           
         </div>   
       </div> 
     </div>              
              

  
  );
}

export default IntroCompLogin ; 
export { default as IntroCompLogin } from './IntroCompLogin';